import React, {Component} from 'react';
import {Image, Linking, Text, TextInput, ImageBackground, View} from 'react-native';
import {Button} from 'react-native-elements';
import {connect} from 'react-redux';
import {login, register, getAddresses, clearError} from '../../../core/actions/User';
import Loading from '../../components/Loading';
import {NavigationActions, StackActions} from 'react-navigation';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {LINK_FORGOT_PASSWORD} from '../../config/config';

import {ApplicationManager} from '../../../core/managers';

// import I18n from '../../i18n/i18n';

import styles from './styles';
import Container from "../../components/Container";

class Authentication extends Component {

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    constructor(props) {
        super(props);

        this.ACTIONS = {
            LOGIN: 0,
            REGISTER: 1
        };

        this.state = {
            nextView: this.hasParam('nextView') ? this.props.navigation.state.params.nextView : null,
            action: this.ACTIONS.LOGIN,
            email: null,
            password: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
        };

    }

    hasParam(param) {
        if (typeof this.props.navigation.state.params !== 'undefined' && param in this.props.navigation.state.params) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.user.error !== null) {
            setTimeout(() => {
                this.props.clearError();
            }, 5000);
        }

    };

    getFormattedError(error = 'BAD_CREDENTIAL') {
        switch (error) {
            case 'Validation Failed':
                return 'Une erreur est survenue';

            case 'BAD_CREDENTIAL':
            default:
                return 'Identifiant ou mot de passe incorrect';
        }
    }

    /* ======---------------------------
               Actions
   --------------------------------------------===== */
    changeForm(action) {
        this.setState({
            action: action
        });
    };

    forgotPassword() {
        Linking.openURL(LINK_FORGOT_PASSWORD)
            .catch(
                err => console.error('An error occurred', err)
            );
    }

    login() {
        let {email, password} = this.state;

        this.props.login(email, password)
            .then(() => {

                if (this.props.user.logged) {
                    this.finalizeAuthenticationSuccess();
                }

                //TODO supprimer le mdp, clearInput()
            })
        ;
    };

    register() {
        let {firstName, lastName, phoneNumber, email, password} = this.state;

        this.props.register(firstName, lastName, phoneNumber, email, password)
            .then(() => {

                if (this.props.user.logged) {
                    this.finalizeAuthenticationSuccess();
                }

                //TODO supprimer le mdp, clearInput()
            });
    };


    finalizeAuthenticationSuccess() {

        ApplicationManager.saveUserData(this.props.user).then();

        // Going back if no next view
        if (this.state.nextView === null) {
            this.props.navigation.goBack();
        }
        else {
            const resetAction = StackActions.reset({
                index: 2,
                actions: [
                    NavigationActions.navigate({routeName: 'Index'}),
                    NavigationActions.navigate({routeName: 'Cart'}),
                    NavigationActions.navigate({routeName: 'CartDetails'}),
                ]
            });
            this.props.navigation.dispatch(resetAction);
        }

    }


    /* ======---------------------------
               Renders
   --------------------------------------------===== */


    renderRegisterView() {
        const error = this.props.user.error;
        const detailedErrors = this.props.user.detailedErrors;
        // let translatedError = I18n.t("error." + error, {defaultValue: I18n.t("error.DEFAULT_AUTHENTICATION_ERROR")});
        let translatedError = this.getFormattedError(error);
        
        if(detailedErrors !== null && detailedErrors !== "") {
            translatedError += detailedErrors;
        }
        //TODO detailedErrors
        return (
            <KeyboardAwareScrollView>
                <Container size={"small"}>
                    <View style={styles.body}>

                        <Text style={styles.title}>Inscription</Text>

                        {error &&
                            <Text style={styles.error}>
                                {translatedError}
                            </Text>
                        }


                        <Text style={styles.label}>{'Prénom'} *</Text>
                        <TextInput
                            style={styles.input}
                            placeholder={'Prénom'}
                            keyboardType="default"
                            underlineColorAndroid="transparent"
                            onChangeText={(text) => this.setState({firstName: text})}
                            returnKeyType="next"
                            autoCorrect={false}
                            onSubmitEditing={(event) => {
                                this.refs.RegisterLastNameInput.focus();
                            }}
                        />

                        <Text style={styles.label}>{'Nom'} *</Text>
                        <TextInput
                            ref="RegisterLastNameInput"
                            style={styles.input}
                            placeholder={'Nom'}
                            keyboardType="default"
                            underlineColorAndroid="transparent"
                            onChangeText={(text) => this.setState({lastName: text})}
                            returnKeyType="next"
                            autoCorrect={false}
                            onSubmitEditing={(event) => {
                                this.refs.RegisterPhoneNumberInput.focus();
                            }}
                        />

                        <Text style={styles.label}>{'Numéro de téléphone'} *</Text>
                        <TextInput
                            ref="RegisterPhoneNumberInput"
                            style={styles.input}
                            placeholder={'Numéro de téléphone'}
                            keyboardType="phone-pad"
                            underlineColorAndroid="transparent"
                            onChangeText={(text) => this.setState({phoneNumber: text})}
                            returnKeyType="next"
                            autoCorrect={false}
                            onSubmitEditing={(event) => {
                                this.refs.RegisterEmailInput.focus();
                            }}
                        />

                        <Text style={styles.label}>{'Adresse email'} *</Text>
                        <TextInput
                            ref="RegisterEmailInput"
                            style={styles.input}
                            placeholder={'Adresse email'}
                            keyboardType="email-address"
                            spellCheck={false}
                            autoComplete={false}
                            autoCorrect={false}
                            underlineColorAndroid="transparent"
                            onChangeText={(text) => this.setState({email: text})}
                            returnKeyType="next"
                            onSubmitEditing={(event) => {
                                this.refs.RegisterPasswordInput.focus();
                            }}
                        />

                        <Text style={styles.label}>{'Mot de passe'} *</Text>
                        <TextInput
                            ref="RegisterPasswordInput"
                            style={styles.input}
                            placeholder={'Mot de passe'}
                            onChangeText={(text) => this.setState({password: text})}
                            autoCapitalize="none"
                            secureTextEntry={true}
                            spellCheck={false}
                            autoComplete={false}
                            autoCorrect={false}
                            underlineColorAndroid="transparent"
                            onSubmitEditing={(event) => {
                                this.register()
                            }}
                            returnKeyType="go"
                        />

                        <Button
                            buttonStyle={styles.buttonStyleSecondary}
                            title={'Créer mon compte'}
                            onPress={() => this.register()}
                        />

                        <Button
                            onPress={() => this.changeForm(this.ACTIONS.LOGIN)}
                            buttonStyle={styles.buttonStyleTransparent}
                            title={'Vous avez déjà un compte ?'}
                        />

                        <View
                            style={{height: 40}}
                        />

                    </View>
                </Container>
            </KeyboardAwareScrollView>

        )
    }

    renderLoginView() {
        const error = this.props.user.error;

        return (
            <KeyboardAwareScrollView>

                <View style={styles.body}>

                    <ImageBackground
                        style={styles.containerImage}
                        imageStyle={styles.containerImage}
                        source={require('../../resources/img/background-pattern.jpg')}
                    >
                        <Image
                            style={styles.logo}
                            source={require('../../resources/img/logo.png')}
                        />
                    </ImageBackground>

                    <Container size={"small"}>
                        {error && <Text style={styles.error}>{this.getFormattedError(error)}</Text>}

                        <Text style={styles.label}>{'Adresse email'} *</Text>
                        <TextInput
                            style={styles.input}
                            placeholder={'Adresse email'}
                            keyboardType="email-address"
                            autoCapitalize="none"
                            spellCheck={false}
                            autoComplete={false}
                            autoCorrect={false}
                            underlineColorAndroid="transparent"
                            onChangeText={(text) => this.setState({email: text})}
                            returnKeyType="next"
                            onSubmitEditing={(event) => {
                                this.refs.PasswordInput.focus();
                            }}
                        />

                        <Text style={styles.label}>{'Mot de passe'} *</Text>
                        <TextInput
                            ref="PasswordInput"
                            placeholder={'Mot de passe'}
                            style={styles.input}
                            autoCapitalize="none"
                            secureTextEntry={true}
                            spellCheck={false}
                            autoComplete={false}
                            autoCorrect={false}
                            underlineColorAndroid="transparent"
                            onSubmitEditing={(event) => {
                                this.login()
                            }}
                            returnKeyType="go"
                            onChangeText={(text) => this.setState({password: text})}/>


                        <Button
                            buttonStyle={styles.buttonStylePrimary}
                            title={'Connexion'}
                            onPress={() => this.login()}
                        />

                        <Button
                            buttonStyle={styles.buttonStyleTransparent}
                            title={'Mot de passe oublié'}
                            onPress={() => this.forgotPassword()}
                        />

                        <View
                            style={{height: 10}}
                        />
                        <Button
                            buttonStyle={styles.buttonStyleTransparent}
                            title={'Vous n\'avez pas encore de compte ?'}
                            onPress={() => this.changeForm(this.ACTIONS.REGISTER)}
                        />
                    </Container>
                </View>
            </KeyboardAwareScrollView>


        )
    }


    render() {
        let {action} = this.state;
        const loading = this.props.user.loading;

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern-reverse.jpg')}>


                {action === this.ACTIONS.LOGIN && this.renderLoginView()}
                {action === this.ACTIONS.REGISTER && this.renderRegisterView()}

                {loading && <Loading/>}
            </ImageBackground>

        )
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        register: (firstName, lastName, phoneNumber, email, password) =>
            dispatch(register(firstName, lastName, phoneNumber, email, password)),
        login: (email, password) => dispatch(login(email, password)),
        getAddresses: () => dispatch(getAddresses()),
        clearError: () => dispatch(clearError()),
    }
}

/* Second method
* const mapDispatchToProps = {
        addToCart
};
* */

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Authentication);