// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        width: null,
        height: null,
    },
    image: {
        height: 200,
        width: width,
    },
    content: {
        padding: 10,
        marginBottom: 15,
        backgroundColor: '#fff',
        elevation: 2,
    },
    title: {
        color: '#65256b',
        fontWeight: 'bold',
        fontSize: 22,
        textAlign: 'center',
        marginTop: 20,
        backgroundColor: 'transparent'
    },
    description: {
        textAlign: 'justify',
        marginBottom: 15,
        padding: 10,
    }
});
