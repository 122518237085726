/*
* @flow
*
* A number button matrix
*/
import React from 'react';
import { TouchableOpacity, ImageBackground, Dimensions, View, Text, ScrollView, StyleSheet, Image } from 'react-native';
import Button from 'react-native-button';
import {connect} from 'react-redux';
import Dialog, { DialogTitle, DialogButton, SlideAnimation } from 'react-native-popup-dialog';
// import {CachedImage} from 'react-native-cached-image';
import {plusOneProduct} from '../../../../core/actions/Cart';
// import I18n from '../../../i18n/i18n';

import {CategoryIds} from '../../../config/config';
import {styles} from './styles';

class ProductPopup extends React.Component {

    findDisplayableProducts () {

        //TODO Faut un systeme de favori popup
        let products = [];
        for(let i = 0; i < this.props.products.length; i++) {
            let id = this.props.products[i].id;

            if(this.props.products[i].category !== null) {
                let categoryCode = this.props.products[i].category.code;
                if(categoryCode === CategoryIds.APPETIZER || categoryCode === CategoryIds.DESSERT || id === 'MKJUSBIS' || id === 'MKJUSGIN') {
                    if(id !== 'MKPIMANT') {
                        products.push(this.props.products[i]);
                    }
                }
            }
        }

        return products.reverse();
    }

    _render_product(product) {
        // console.log(product.imageUrl);
        return (
            <TouchableOpacity key={product.id} onPress={() => {
                this.props.plusOneProduct(product.id, null, product.price.amount);
                //this.props.dismiss();
            }}>
            
                <ImageBackground
                    style={styles.cover}
                    imageStyle={styles.coverImage}
                    source={{uri: product.imageUrl}}>

                        <View style={styles.coverTop}>
                            <View style={styles.coverTopLeft}>
                                {product.novelty &&
                                <Text style={styles.novelty}>Nouveau</Text>
                                }
                            </View>

                            <View style={styles.coverTopRight}>
                            </View>
                        </View>

                        <View style={styles.coverBottom}>
                            <View style={styles.coverBottomLeft}>
                                <Text style={styles.title}>{product.name}</Text>
                            </View>
                            <View>
                                <Text style={styles.price}>{(product.price.amount / 100).toFixed(2)} €</Text>
                            </View>
                        </View>
                </ImageBackground>
            </TouchableOpacity>
        );
    }

    render() {
        let products = this.findDisplayableProducts();
        
        return (
          <Dialog
            visible={this.props.visible}
            dismissOnTouchOutside={false}
            dialogStyle={styles.popup}
            // onDismissed={this.props.onDismissed}
            ref={(popupDialog) => {
                this.props.setRef(popupDialog)
            }}
            dialogAnimation={new SlideAnimation({ slideFrom: 'bottom' })}
            dialogTitle={
                <DialogTitle
                    title="Complétez votre panier"
                    titleStyle={{backgroundColor:"#65256b" }}
                    titleTextStyle={{color: '#FFF'}}
                />
            }>
              <ScrollView style={styles.container}>

                { Object.keys(products).map((key) => {
                    return this._render_product(products[key])
                })}


                <View
                    style={{height: 15}}
                />

              </ScrollView>

              <Button
                style={{fontSize: 14, color: '#000'}}
                containerStyle={{
                  overflow: 'hidden',
                  borderRadius: 2,
                  backgroundColor: '#fff',
                  paddingLeft: 3,
                  paddingRight: 3,
                  paddingTop: 11,
                  height: 40,
                  margin: 2,
                }}
                onPress={() => {
                  this.props.dismiss();
                }}
              >
                Valider
              </Button>
          </Dialog>
        );
    }
};



function mapStateToProps(state) {
    return {
        products: state.data.data.products,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        plusOneProduct: (id, subId, price) => dispatch(plusOneProduct(id, subId, price)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductPopup);
