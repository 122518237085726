import {Dimensions, StyleSheet} from "react-native";

const {height, width} = Dimensions.get('window');

export const styles = StyleSheet.create({
  popup: {
    width: width - 50,
    maxWidth: 500,
    height: height * 0.7,
    position: "absolute",
    top: 30,
  },
  container: {
  },
  cover: {
    marginBottom: 5,
    height: height * 0.3,
    backgroundColor: 'transparent',
  },
  coverImage: {
    resizeMode: "cover",
    borderRadius: 2,
  },
  coverOverlay: {
    width: '100%',
    height: '100%',
  },
  coverOverlayImage: {
    resizeMode: "stretch",
  },
  coverTop: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  coverTopLeft: {},
  coverTopRight: {
    flex: -1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  coverBottom: {
    backgroundColor: '#00000090',
    flex: -1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    position: "relative",
  },
  coverBottomLeft: {
    flex: -1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  coverBottomRight: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
  },
  subtitle: {
    color: '#fda127',
    fontWeight: 'bold',
    fontSize: 14,
  },
  price: {
    color: 'white',
    fontWeight: '300',
    fontSize: 36,
    marginBottom: 0,
  },
  novelty: {
    color: 'white',
    fontSize: 14,
    marginLeft: 10,
    marginTop: 15,
    padding: 5,
    backgroundColor: '#fda127',
  },
  cancelIcon: {
    padding: 0,
    top: 0,
    borderTopWidth: 0,
    backgroundColor: 'rgba(40, 40, 40, 0.3)',
    borderLeftColor: 'rgba(0, 0, 0, 0)',
    borderLeftWidth: 30,
    borderRightWidth: 0,
    borderBottomColor: 'rgba(0, 0, 0, 0)',
    borderBottomWidth: 30,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 80,

  },
  cancelIconIcon: {
    top: 8,
    right: 8,
  },
  validateButton: {
    margin: 4,
    borderRadius: 2,
    backgroundColor: '#fda127',
  },
});
