let packageInformation = require('../../package.json');

let env = 0;
if(packageInformation.environment === "pre-production") {
    env = 1;
    // This is for debugging
    // GLOBAL.XMLHttpRequest = GLOBAL.originalXMLHttpRequest || GLOBAL.XMLHttpRequest;
}
else if(packageInformation.environment === "production") {
    env = 2;
}
// else {
//     GLOBAL.XMLHttpRequest = GLOBAL.originalXMLHttpRequest || GLOBAL.XMLHttpRequest;
// }

const MOLLIE_API_KEYS = [
    "test_aFQVnqEj7zvQhs7Wsz5EshfF8EvFzt",
    "test_aFQVnqEj7zvQhs7Wsz5EshfF8EvFzt",
    "live_cDhemFsUFK8s7bVHabCJAMcDC7Vk67"
];

export const MOLLIE_API_KEY = MOLLIE_API_KEYS[env];

export const PAYMENT_URL = "https://api.mollie.com/v2/payments";

export const APP_URLS = [
    "http://xoxotime:8888/",
    "https://mariamskitchen.snowpact.com/",
    "https://backend.mariamskitchen.fr/",
];

export const API_PRODUCTS_URL               = APP_URLS[env] + 'api/products';
export const API_FORMULAS_URL               = APP_URLS[env] + 'api/formulas';
export const API_ZONES_URL                  = APP_URLS[env] + 'api/zones';
export const API_CONFIG_URL                 = APP_URLS[env] + 'api/configs';

export const API_VALIDATE_CART_URL          = APP_URLS[env] + 'api/carts';
export const API_VALIDATE_CART_PAYMENT      = APP_URLS[env] + 'api/carts/payment';
export const API_SET_ID_PAYMENT             = APP_URLS[env] + 'api/carts/{{orderId}}/payment';
export const API_ORDER_IS_PAYED             = APP_URLS[env] + 'api/carts/{{orderId}}/payed';
export const API_PROMOTION_CODE_URL         = APP_URLS[env] + 'api/promotion_code/{{code}}';
export const API_ORDER_STATUS               = APP_URLS[env] + 'api/carts/{{orderId}}/state';
export const API_ORDER                      = APP_URLS[env] + 'api/carts/{{orderId}}';
export const API_ORDERS_URL                 = APP_URLS[env] + 'api/carts/customer';
export const API_LOGIN                      = APP_URLS[env] + 'api/login_check';
export const API_REGISTER                   = APP_URLS[env] + 'api/customers/register';
export const API_ADDRESSES                  = APP_URLS[env] + 'api/customers/address';

export const URL_PAYMENT_SUCCESS            = APP_URLS[env] + 'api/carts/order/confirmation';
export const URL_PAYMENT_UNCERTAIN          = APP_URLS[env] + 'api/carts/order/uncertain';
export const URL_PAYMENT_CANCEL             = APP_URLS[env] + 'api/carts/order/cancel';

export const LINK_FORGOT_PASSWORD           = APP_URLS[env] + 'admin/change-password/request';

export const URL_MANIFEST                   = APP_URLS[env] + 'manifest.json';



export const PRODUCT_PIMENTO_CODE = 'MKPIMANT';

export const BAD_CREDENTIAL = "BAD_CREDENTIAL";

export const INVALID_TOKEN = "INVALID_TOKEN";
export const MISSING_TOKEN = "MISSING_TOKEN";
export const EXPIRED_TOKEN = "EXPIRED_TOKEN";


export const CONFIG_DELAY_DELIVERY = "DELAY_DELIVERY";
export const OPENING_HOURS = "OPENING_HOURS";


export const CART_ERROR_INVALID_ARTICLE = "INVALID_ARTICLE";

export const ArticleTypes = {
    PRODUCT: 'products',
    FORMULA: 'formulas'
};

export const CategoryIds = {
    APPETIZER: 'STARTER',
    COURSE: 'DISH',
    DESSERT: 'DESSERT',
    DRINK: 'DRINK',
};


