import React, {Component} from 'react';
import {ImageBackground, View, ScrollView, Text} from 'react-native';
// import I18n from '../../i18n/i18n';
// import {CachedImage} from 'react-native-cached-image';

import styles from './styles';
import Container from "../../components/Container";

class ProductDetails extends Component {

    // componentWillReceiveProps(nextProps) {

    // }

    constructor(props) {
        super(props);
    }

    // componentDidMount() {

    // };


    /* ======---------------------------
               Renders
   --------------------------------------------===== */
    render() {
        const product = this.props.navigation.state.params.product;
        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>

                <ScrollView>

                    <ImageBackground
                        source={{uri: product.imageUrl}}
                        style={styles.image}
                        resizeMode="cover">
                    </ImageBackground>

                    <View style={styles.content}>
                        <Container size={'small'}>
                            <Text style={styles.title}>
                                {product.name}
                            </Text>

                            <Text style={styles.description}>
                                {product.description && product.description}
                                {!product.description && "Vide"}
                            </Text>
                        </Container>
                    </View>

                </ScrollView>
            </ImageBackground>
        )
    }
}


export default ProductDetails;
