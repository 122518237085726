/*
* @flow
*
* A number button matrix
*/
import {TouchableOpacity, Text, View, Image, ImageBackground, ScrollView, Platform} from 'react-native';
import {connect} from 'react-redux';
import Entypo from 'react-native-vector-icons/Entypo';
import {logout} from '../../../../core/actions/User';

import SafeAreaView from 'react-native-safe-area-view';
import {DrawerItems} from 'react-navigation-drawer';

import React from 'react';

import styles from './styles';

const DrawerMenu = (props) => (
    <ScrollView>
      <SafeAreaView
        style={styles.container}
        forceInset={{ top: 'always', horizontal: 'never' }}
      >
        {props.user.logged &&
          <View style={styles.banner}>
              <Image
                  style={styles.avatar}
                  source={require('../../../resources/img/burger.png')}/>
              <View style={styles.bannerCenter}>
                  <Text style={styles.bannerTextBold}>Bienvenue !</Text>
                  <Text style={styles.bannerText}>{props.user.formattedName}</Text>
              </View>
              <TouchableOpacity style={styles.logout} onPress={() => props.logout()}>
                  <Entypo size={24} name="log-out" color="#fff"/>
              </TouchableOpacity>
          </View>
        }

        {Platform.OS === 'ios' ? (
            <ImageBackground
                style={styles.containerImage}
                imageStyle={styles.containerImage}
                source={require('../../../resources/img/background-pattern.jpg')}
            >
                <Image
                    style={styles.stretch}
                    source={require('../../../resources/img/logo.png')}
                />
            </ImageBackground>
        ) : (
            <Image
                style={styles.androidLogo}
                source={require('../../../resources/img/logo.jpg')}
            />
        )}
        
        <DrawerItems {...props} />
      </SafeAreaView>
    </ScrollView>
);

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrawerMenu);


// TODO la largeur du menu doit être la dimension de l'écran *0.8