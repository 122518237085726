import {
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT, ADDRESS_SUCCESS, ADDRESS_FAILURE,
    CLEAR_ERROR
} from '../actions/User'

//TODO add firstName,... and a date to check if there is new data
const initialState = {
    logged: false,
    token: null,
    username: null,
    formattedName: null,
    loading: false,
    error: null,
    detailedErrors: null,
    addresses: null
};

export default function data(state = initialState, action) {
    switch (action.type) {
        case LOGIN_PENDING:
            return state = updateObject(state, {
                logged: false,
                token: null,
                username: null,
                formattedName: null,
                error: null,
                detailedErrors: null,
                loading: true,
            });
        case LOGIN_FAILURE:
            return state = updateObject(state, {
                logged: false,
                token: null,
                username: null,
                formattedName: null,
                error: action.error,
                detailedErrors: action.detailedErrors,
                loading: false,
            });
        case LOGIN_SUCCESS:
            return state = updateObject(state, {
                logged: true,
                token: action.token,
                username: action.username,
                formattedName: action.formattedName,
                error: null,
                detailedErrors: null,
                loading: false,
            });
        case LOGOUT:
            return state = updateObject(state, {
                logged: false,
                token: null,
                username: null,
                formattedName: null,
                error: null,
                detailedErrors: null,
                loading: false,
                addresses: null,
            });

        case CLEAR_ERROR:
            return state = updateObject(state, {
                error: null,
            });

        case ADDRESS_SUCCESS:
            return state = updateObject(state, {
                addresses: action.addresses
            });

        case ADDRESS_FAILURE:
            return state = updateObject(state, {
                addresses: null
            });

        default:
            return state;
    }
};

function updateObject(myObj, value) {
    return Object.assign({}, myObj, value);
}
