// @flow
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        // remove width and height to override fixed static size
        width: null,
        height: null,
    }
});
