/*
 * action types
 */
import axios from 'axios';
import {API_PRODUCTS_URL, API_FORMULAS_URL, API_ZONES_URL, API_CONFIG_URL} from '../../src/config/config';

export const FETCHING_DATA_PENDING = 'FETCHING_DATA_PENDING';
export const FETCHING_DATA_SUCCESS = 'FETCHING_DATA_SUCCESS';
export const FETCHING_DATA_FAILURE = 'FETCHING_DATA_FAILURE';

export const FETCHING_CONFIG_PENDING = 'FETCHING_CONFIG_PENDING';
export const FETCHING_CONFIG_SUCCESS = 'FETCHING_CONFIG_SUCCESS';
export const FETCHING_CONFIG_FAILURE = 'FETCHING_CONFIG_FAILURE';

// import FetchHelper from '../managers/fetchHelper';


/*
 * action creators
 */

export function getDataPending() {
    return {
        type: FETCHING_DATA_PENDING
    }
}

export function getDataSuccess(data, key) {
    return {
        type: FETCHING_DATA_SUCCESS,
        data,
        key
    }
}

export function getDataFailure(error) {
    return {
        type: FETCHING_DATA_FAILURE,
        error
    }
}

export function getConfigPending() {
    return {
        type: FETCHING_CONFIG_PENDING
    }
}

export function getConfigSuccess(config) {
    return {
        type: FETCHING_CONFIG_SUCCESS,
        config
    }
}

export function getConfigFailure(error) {
    return {
        type: FETCHING_CONFIG_FAILURE,
        error
    }
}

export  function fetchDataFromAPI(url, key) {
    return async (dispatch) => {
        // const response = FetchHelper.decoratedFetch(dispatch, url)
        //             .then(responseData => );
        const data = await axios.get(url)
                                .then(response => {
                                    if (response.data) {
                                        dispatch(getDataSuccess(response.data, key));
                                    }
                                    else {
                                        dispatch(getDataFailure(response.message));
                                    }
                                });
        return data;
    }
}

export function fetchConfigFromAPI() {
    return async (dispatch) => {

        return await axios.get(API_CONFIG_URL)
                        .then(responseData => {
                            if (responseData.data) {
                                let config = {};
                                for(let i = 0; i < responseData.data.length; i++) {
                                    config[responseData.data[i].key] = responseData.data[i].value;
                                }

                                dispatch(getConfigSuccess(config));
                            }
                            else {
                                dispatch(getConfigFailure(responseData.message));
                            }
                        })
    }
}


export function fetchData() {
    return (dispatch) => {
        dispatch(fetchDataFromAPI(API_PRODUCTS_URL, 'products'));
        dispatch(fetchDataFromAPI(API_FORMULAS_URL, 'formulas'));
        dispatch(fetchDataFromAPI(API_ZONES_URL, 'zones'));
        dispatch(fetchConfigFromAPI());
    }
}
