import {
    FETCHING_ORDERS,
    FETCHING_ORDERS_SUCCESS,
    FETCHING_ORDERS_FAILURE,
} from '../actions/Order'

const initialState = {
    list: null,
    loading: false
};

export default function order(state = initialState, action) {
    switch (action.type) {
        case FETCHING_ORDERS:
            return state = updateObject(state, {
                list: null,
                loading: true
            });
        case FETCHING_ORDERS_SUCCESS:
            return state = updateObject(state, {
                list: action.list,
                loading: false
            });
        case FETCHING_ORDERS_FAILURE:
            return state = updateObject(state, {
                list: null,
                loading: false
            });

        default:
            return state;
    }
};

function updateObject(myObj, value) {
    return Object.assign({}, myObj, value);
}
