// import {combineReducers} from 'redux';
import {ArticleTypes} from '../../src/config/config';

import {
    CLEAR_CART,
    PLUS_ONE_PRODUCT,
    MINUS_ONE_PRODUCT,
    PREPARE_FORMULA,
    PLUS_ONE_PRODUCT_TO_FORMULA,
    VALIDATE_FORMULA,
    REMOVE_PRODUCT,
    SET_DELIVERY_DATETIME,
    SET_DELIVERY_ADDRESS,
    SET_DETAILS,
    VALIDATE_CART_SUCCESS,
    VALIDATE_CART_FAILURE
} from '../actions/Cart'


export const INITIAL_STATE = {
    header: {
        numberOfArticles: 0,
        deliveryCharges: 0,
        total: 0,
        minAmountDelivery: -1,
    },
    articles: {},
    formulaCreator: {
        formula: []
    },
    delivery: {
        valid: false,
        address: null,
        latitude: null,
        longitude: null,
        department: null,
        complementary: null,
        hour: null,
        minute: null,
        date: null,
        asap: false
    },
    details: {
        remark: null,
        salt: false,
        cutlery: 0,
        complementaryAddress: null,
        promoCode: null,
        promoCodePercent: 0,
    },
    order: {
        id: -1,
        error: null,
        paymentUrl : null,
        paymentPostData: null,
        address: null,
        wishDeliveryDate: null,
        paymentOnline: false
    }
};

export default function cart(state = INITIAL_STATE, action) {

    let articleTotalPrice = 0;

    let articleIndex = -1;

    if(action.type === PLUS_ONE_PRODUCT || action.type === MINUS_ONE_PRODUCT || action.type === REMOVE_PRODUCT) {
        // Find the product index
        Object.keys(state.articles).forEach(function (key) {
            let article = state.articles[key];
            if (article.id === action.id && article.subId === action.subId) {
                articleIndex = key;
            }
        });
    }

    switch (action.type) {
        case CLEAR_CART:
            state = Object.assign({}, state, INITIAL_STATE);
            return state;

        case PLUS_ONE_PRODUCT:

            // Add a new product if it was not in the cart
            if (articleIndex === -1) {
                state = Object.assign({}, state, {
                    articles: {
                        ...state.articles,
                        [state.header.numberOfArticles]: {
                            id: action.id,
                            subId: null,
                            quantity: 1,
                            price: action.price,
                            extraPrice: 0,
                            articleType: action.articleType
                        }
                    }
                });

                articleTotalPrice = action.price;
            }
            // Add one quantity
            else {
                state.articles[articleIndex].quantity = state.articles[articleIndex].quantity + 1;
                articleTotalPrice = state.articles[articleIndex].price + state.articles[articleIndex].extraPrice;
            }

            state = Object.assign({}, state, {
                header: {
                    ...state.header,
                    numberOfArticles: state.header.numberOfArticles + 1,
                    total: state.header.total + articleTotalPrice,
                    deliveryCharges: state.header.deliveryCharges
                }
            });

            return state;

        case MINUS_ONE_PRODUCT:

            // Remove one quantity
            state.articles[articleIndex].quantity = state.articles[articleIndex].quantity - 1;
            articleTotalPrice = state.articles[articleIndex].price + state.articles[articleIndex].extraPrice;

            // Remove the product if there is no more product
            if (state.articles[articleIndex].quantity < 1) {
                state.articles = removeKey(state.articles, articleIndex);
            }

            state = Object.assign({}, state, {
                header: {
                    ...state.header,
                    numberOfArticles: state.header.numberOfArticles - 1,
                    total: state.header.total - articleTotalPrice,
                    deliveryCharges: state.header.deliveryCharges
                }
            });
            return state;

        case PREPARE_FORMULA:

            state = Object.assign({}, state, {
                formulaCreator: {
                    formula: {
                        list: [],
                        quantity: 1,
                        price: action.price,
                        extraPrice: 0,
                        articleType: action.articleType,
                        id: action.id,
                        subId: getNumberOfSameFormulas(action.id)
                    }
                }
            });

            return state;

        case VALIDATE_FORMULA:

            state = Object.assign({}, state, {
                articles: {
                    ...state.articles,
                    [state.header.numberOfArticles]: state.formulaCreator.formula
                }
            });

            state = Object.assign({}, state, {
                header: {
                    ...state.header,
                    numberOfArticles: state.header.numberOfArticles + 1,
                    total: state.header.total + state.formulaCreator.formula.extraPrice + state.formulaCreator.formula.price,
                    deliveryCharges: state.header.deliveryCharges
                }
            });

            return state;

        case PLUS_ONE_PRODUCT_TO_FORMULA:

            // Add the product to the list
            state.formulaCreator.formula.list = Object.assign({}, state.formulaCreator.formula.list, {
                ...state.formulaCreator.formula.list,
                [action.compositionStep]: {
                    id: action.productId,
                    extra: action.price
                }
            });

            let formulaTotalExtraPrice = 0;

            Object.keys(state.formulaCreator.formula.list).map(function (key) {
                formulaTotalExtraPrice += state.formulaCreator.formula.list[key].extra;
            });

            //Removing old extra price from total and adding the new one
            state.formulaCreator.formula.extraPrice = formulaTotalExtraPrice;
            return state;

        case REMOVE_PRODUCT:

            state.articles = removeKey(state.articles, articleIndex);

            return state;

        case SET_DELIVERY_ADDRESS:

            state = Object.assign({}, state, {
                delivery: {
                    ...state.delivery,
                    address: action.address,
                    latitude: action.latitude,
                    longitude: action.longitude,
                    department: action.department,
                    complementary: action.complementary,
                    valid: state.delivery.date !== null
                }
            });

            state = Object.assign({}, state, {
                header: {
                    ...state.header,
                    minAmountDelivery: action.minAmountDelivery
                }
            });

            return state;

        case SET_DELIVERY_DATETIME:

            state = Object.assign({}, state, {
                delivery: {
                    ...state.delivery,
                    date: action.date,
                    hour: action.hour,
                    minute: action.minute,
                    asap: action.asap,
                    valid: state.delivery.address !== null
                }
            });

            return state;

        case SET_DETAILS:

            state = Object.assign({}, state, {
                details: {
                    ...state.details,
                    remark: action.remark,
                    salt: action.salt,
                    cutlery: action.cutlery,
                    promoCode: action.promoCode,
                    promoCodePercent: action.promoCodePercent,
                }
            });

            state = Object.assign({}, state, {
                delivery: {
                    ...state.delivery,
                    complementary: action.complementaryAddress,
                }
            });

            return state;

        case VALIDATE_CART_SUCCESS:

            state = Object.assign({}, state, {
                order: {
                    id: action.id,
                    address: action.address,
                    wishDeliveryDate: action.wishDeliveryDate,
                    paymentUrl: action.paymentUrl,
                    paymentPostData: action.paymentPostData,
                    paymentOnline: action.paymentOnline,
                    error: null
                }
            });

            return state;

        case VALIDATE_CART_FAILURE:

            state = Object.assign({}, state, {
                order: {
                    id: -1,
                    address: null,
                    wishDeliveryDate: null,
                    paymentUrl: null,
                    paymentPostData: null,
                    paymentOnline: action.paymentOnline,
                    error: action.error
                }
            });

            return state;


        default:
            return state;
    }

    function removeKey(myObj, deleteKey) {
        return Object.assign(
            {},
            ...Object.entries(myObj)
                .filter(([k]) => k !== deleteKey)
                .map(([k, v]) => ({[k]: v})));
    }

    function getNumberOfSameFormulas(id) {
        let numberOfSameFormulas = 0;
        Object.keys(state.articles).forEach(function (key) {
            if (state.articles[key].id === id && state.articles[key].articleType === ArticleTypes.FORMULA) {
                numberOfSameFormulas++;
            }
        });
        return numberOfSameFormulas;
    }

}
