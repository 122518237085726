/*
* @flow
*
* The log in reducer
*/
import { REHYDRATE } from 'redux-persist/lib/constants';

// import type, { Action } from '../';

export default function rehydrated(state = false, action) {
  if (action.type === REHYDRATE) {
    return true;
  }
  return state;
}
