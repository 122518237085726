// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
    },
    keyboardAvoiding: {
        flex: 1,
    },
    cart: {
        flex: 1,
    },
    payment: {
        flex: -1,
        flexDirection: 'column'
    },
    item: {
        backgroundColor: 'white',
        marginTop: 12,
        marginLeft: 7,
        marginRight: 7,
        borderColor: '#d6d7da',
        padding: 15,
        paddingBottom: 10,
        paddingTop: 8,
        flex: 1,
        flexDirection: 'column',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    recap: {
        backgroundColor: '#fff',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,

    },
    recapLine: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 5,
    },
    recapLineTitle: {
        flex: 1,
        fontSize: 15
    },
    recapLineData: {
        flex: -1,
        fontSize: 15
    },
    horizontalSeparator: {
        borderBottomColor: '#e0e3e5',
        borderBottomWidth: 1,
        marginTop: 12,
        marginBottom: 12,
    },
    bold: {
        fontWeight: 'bold'
    },
    lineThrough: {
        textDecorationLine: 'line-through',
        color: '#fda127',
        fontSize: 11,
        marginRight: 5,
    },
    boldColor: {
        fontWeight: 'bold',
        color: '#fda127'
    },
    buttonBlock: {
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 5,
        marginBottom: 25,
        marginTop: 10
    },
    payButtonText: {
        fontSize: 14,
        textAlign: 'center'
    },
    payButtonContainer: {
        alignSelf: 'stretch',
    },
    payButton: {
        borderRadius: 2,
        alignSelf: 'stretch'
    },
    payNowButton: {
        backgroundColor: '#fda127',
    },
    payAtDeliveryButton: {
        backgroundColor: '#5c2462',
    },
    standardButton: {
        backgroundColor: '#5c2462',
    },
    title: {
        fontSize: 17,
        fontWeight: 'bold',
        color: '#5c2462'
    },
    formTextArea: {
        marginTop: 10,
        marginBottom: 10,
        borderWidth: 1,
        height: 60,
        fontSize: 12,
        padding: 5,
        borderColor: '#d6d7da',
    },
    formInput: {
        flex: 1,
        marginTop: 10,
        marginBottom: 10,
        borderWidth: 1,
        height: 30,
        fontSize: 12,
        padding: 5,
        borderColor: '#d6d7da',
    },
    formInputButton: {
        flex: -1,
    },
    formCheckBox: {
        marginTop: 5,
        flex: 1,
    },
    formCheckBoxText: {
        fontWeight: 'normal',
        fontSize: 12,
    },
    plusMinusButton: {
        alignSelf: 'flex-start',
    },
    plusMinusFormText: {
        marginTop: 5,
        marginLeft: 3,
        marginRight: 3,
    },
    plusMinusFormLabel: {
        marginTop: 6,
        marginLeft: 3,
        fontSize: 12,
    },
    inline: {
        flexDirection: 'row'
    },
    inlineLeft: {
        flex: 1,
    },
    inlineRight: {
        flex: -1,
        color: '#fda127',
    },
    inlineChild: {
        marginRight: 20,
        flexDirection: 'row',
    },
    termsOfUse: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
        margin: 20,
        marginTop: 10,
    },
    customCheckbox: {
        paddingRight: 0,
        marginRight: 0,
    },
    customCheckboxText: {
        paddingRight: 0,
        marginRight: 4,
        fontWeight: 'normal',
        fontSize: 12,
    },
    textCheckbox: {
        flex:1,
        fontSize: 12,
        marginTop: 20,
        textDecorationLine: 'underline',
        textAlign: "left",
        paddingLeft: 0,
    },
});