import React from 'react';
import {ImageBackground, FlatList, View, TouchableOpacity, Text, Dimensions} from 'react-native';
import {Icon} from 'react-native-elements';
import {widthPercentageToDP as wp} from 'react-native-responsive-screen';

// import {CachedImage} from 'react-native-cached-image';

import styles from './styles';
import Container from "../../Container";
import {isMobileDevice} from "../../../services/responsive";

class ArticleItem extends React.PureComponent {
    _onAddPress = (article) => {
        this.props.onAddPressItem(article);
    };

    _onInfoPress = (article) => {
        this.props.onInfoPressItem(article);
    };

    render() {
        let article = this.props.article;
        let priceAttribute = this.props.priceAttribute ? this.props.priceAttribute : 'price';

        return (
            <TouchableOpacity
              onPress={() => this._onAddPress(article)}
              style={isMobileDevice() ?
                {width: wp('95%'), height: 265, marginVertical: 10, marginHorizontal:  wp('2.5%')} :
                {width: 350, margin: 20}}
            >
                <ImageBackground
                    style={isMobileDevice() ? {height: '100%'} : styles.cover}
                    imageStyle={styles.coverOverlayImage}
                    source={{uri: article.imageUrl}}
                >
                    <ImageBackground
                        style={styles.coverOverlay}
                        imageStyle={styles.coverOverlayImage}
                        source={require('../../../resources/img/overlay.png')}
                    >

                        <View style={styles.coverTop}>
                            <View style={styles.coverTopLeft}>
                                {article.novelty &&
                                <Text style={styles.novelty}>Nouveau</Text>
                                }
                            </View>

                            <View style={styles.coverTopRight}>
                                <TouchableOpacity
                                    onPress={() => this._onInfoPress(article)}>
                                    <Icon
                                        style={styles.infoIcon}
                                        name="info"
                                        iconStyle={styles.infoIconIcon}
                                        size={30}
                                        color="#65256b"
                                    />
                                </TouchableOpacity>
                            </View>
                        </View>


                        <View style={styles.coverBottom}>
                            <View style={styles.coverBottomLeft}>
                                <Text style={styles.title}>{article.name}</Text>
                                <Text style={styles.subtitle}>{article.complementaryName}</Text>
                            </View>
                            <View>
                                {(priceAttribute === 'extra' && article.extra.amount > 0) &&
                                <Text style={styles.price}>+{(article.extra.amount / 100).toFixed(2)} €</Text>
                                }
                                {priceAttribute !== 'extra' &&
                                <Text style={styles.price}>{(article.price.amount / 100).toFixed(2)} €</Text>
                                }
                            </View>
                        </View>
                    </ImageBackground>
                </ImageBackground>
            </TouchableOpacity>)
    }
}

class ArticleList extends React.PureComponent {
    state = {};

    _keyExtractor = (item, index) => item.id;

    _onAddPressItem = (article) => {  // updater functions are preferred for transactional updates
        this.props.onAddPress(article);

        if(this.props.scrollOnClick) {
            this.refs.list.scrollToOffset({x: 0, y: 0, animated: true});
        }
    };

    _onInfoPressItem = (article) => {  // updater functions are preferred for transactional updates
        this.props.onInfoPress(article);
    };

    _renderItem = ({item}) => (
        <ArticleItem
            id={item.id}
            article={item}
            onAddPressItem={this._onAddPressItem}
            onInfoPressItem={this._onInfoPressItem}
            priceAttribute={this.props.priceAttribute}
        /> );

    extractImages() {

    }

    render() {
        return (
          <Container>
            <FlatList
                ref="list"
                contentContainerStyle={
                    isMobileDevice() ? {} :
                {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    height: '100vh',
                    overflowY: 'hidden'
                }}
                data={this.props.data}
                extraData={this.state}
                keyExtractor={this._keyExtractor}
                renderItem={this._renderItem}
            />
          </Container>
        );

    }
}

export default ArticleList;