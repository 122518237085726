import React, { Component } from 'react';
import { View, Text, ImageBackground, Linking } from 'react-native';
import { SocialIcon, Button } from 'react-native-elements';
// import I18n from '../../i18n/i18n';

import styles from './styles';
import Container from "../../components/Container";
import {isMobileDevice} from "../../services/responsive";

class Contact extends Component {
    redirectToSocialPage = (action) => {
        switch (action) {
            case 'mail':
                Linking.openURL('mailto:hello@xoxotime.fr?subject=Prise de contact&body=');
                break;
            case 'bug':
                Linking.openURL('mailto:bug@xoxotime.fr?subject=Report d\'un bug&body=Description détaillée du problème : \n\n\nCaptures d\'écran :');
                break;
            case 'phone':
                Linking.openURL('tel:0134461515');
                break;
            case 'facebook':
                Linking.openURL("https://www.facebook.com/xoxotime")
                    .catch(
                        err => console.log('An error occurred', err)
                    );
                break;
            case 'instagram':
                Linking.openURL("https://www.instagram.com/xoxo_time")
                    .catch(
                        err => console.log('An error occurred', err)
                    );
                break;
            case 'twitter':
                Linking.openURL("https://twitter.com/mkitchenparis?lang=fr")
                    .catch(
                        err => console.log('An error occurred', err)
                    );
                break;
            case 'snapchat':
                Linking.openURL("https://www.snapchat.com/add/xoxotime_comedy")
                    .catch(
                        err => console.log('An error occurred', err)
                    );
                break;
        }
    };

    render() {
        return (
            <ImageBackground
                source={require('../../resources/img/background.jpg')}
                style={styles.backgroundImage}
                resizeMode="cover"
            >
                <Container size={'small'}>
                    <View style={styles.central}>
                        <View style={styles.centralBlock}>
                            <Text style={styles.title}>Besoin d'aide ?</Text>
                            <Text style={styles.content}>
                                Notre service clientèle est à votre disposition de 20h à 5h tous les jours, pour prendre vos commandes ou répondre à toutes vos questions.
                            </Text>
                            <Text style={styles.content}>
                                Pensez également à consulter notre foire à question pour la plupart de vos interrogations.
                            </Text>
                            <Text style={styles.content}>
                                Si vous avez rencontré des difficultés techniques lors du passage de votre commande, n'hésitez pas à nous les faire remonter. Nous serons ravis de vous faire bénéfiicer d'un bon de réduction en échange de ces précieuses informations, qui nous permettent de nous améliorer.
                            </Text>
                            {isMobileDevice() ?
                                <>
                                    <View style={styles.buttonBlock}>
                                        <Button
                                          raised
                                          onPress={() => this.redirectToSocialPage('phone')}
                                          buttonStyle={styles.callButton}
                                          title='APPELER'
                                        />
                                        <Button
                                          raised
                                          onPress={() => this.redirectToSocialPage('mail')}
                                          buttonStyle={styles.mailButton}
                                          title='ENVOYER UN MAIL'
                                        />
                                    </View>
                                    <View style={styles.buttonBlock}>
                                        <Button
                                          raised
                                          onPress={() => this.redirectToSocialPage('bug')}
                                          buttonStyle={styles.mailButton}
                                          title='REPORTER UN BUG'
                                        />
                                    </View>
                                </>
                            :
                                <View style={styles.buttonBlock}>
                                    <Button
                                      raised
                                      onPress={() => this.redirectToSocialPage('bug')}
                                      buttonStyle={styles.mailButton}
                                      title='REPORTER UN BUG'
                                    />
                                    <Button
                                        raised
                                        onPress={() => this.redirectToSocialPage('phone')}
                                        buttonStyle={styles.callButton}
                                        title='APPELER'
                                    />
                                    <Button
                                        raised
                                        onPress={() => this.redirectToSocialPage('mail')}
                                        buttonStyle={styles.mailButton}
                                        title='ENVOYER UN MAIL'
                                    />
                                </View>
                            }
                        </View>
                    </View>

                    <View style={styles.bottomBlock}>
                        <SocialIcon
                            onPress={() => this.redirectToSocialPage('facebook')}
                            iconColor="white"
                            style={styles.socialIconStyle}
                            type='facebook'
                        />
                        <SocialIcon
                            onPress={() => this.redirectToSocialPage('instagram')}
                            iconColor="white"
                            style={styles.socialIconStyle}
                            type='instagram'
                        />
                        <SocialIcon
                            onPress={() => this.redirectToSocialPage('snapchat')}
                            iconColor="white"
                            style={styles.socialIconStyle}
                            type='snapchat'
                        />
                        {/* <SocialIcon
                            onPress={() => this.redirectToSocialPage('twitter')}
                            iconColor="white"
                            style={styles.socialIconStyle}
                            type='twitter'/> */}
                    </View>
                </Container>
            </ImageBackground>
        );
    }
}

//Contact.defaultProps = {...me};

export default Contact;
