/*
* @flow
*
* A number button matrix
*/
import {
    View,
    TouchableOpacity
} from 'react-native';

import {Icon} from 'react-native-elements';

import React from 'react';


const handleButton = (props) => {
    props.navigation.toggleDrawer();
};

const DrawerButton = (props) => {
    return (
        <View style={{marginLeft: 10}}>
            <TouchableOpacity onPress={() => { handleButton(props) }}>
                <Icon
                    theme={{ iconFamily: 'FontAwesome' }}
                    name="menu"
                    size={24}
                    color="white"
                />
            </TouchableOpacity>
        </View>
    );
};

export default DrawerButton;
