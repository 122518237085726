// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        width: null,
        height: null,
    },
    block: {
        flex: -1,
        marginTop: 7,
        marginBottom: 5,
        marginLeft: 7,
        marginRight: 7,
        backgroundColor: 'white',
        borderColor: '#d6d7da',
        padding: 20,
        paddingTop: 10,
        flexDirection: 'column',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    infoTitle: {
        fontWeight: 'bold',
        marginTop: 10,
        color: '#0f0f0f'
    },
    mainTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#0f0f0f'
    },
    infoMain: {
        fontSize: 30,
        fontWeight: '200',
    },
    infoSubMain: {
        fontSize: 20,
        fontWeight: '300',
    },
    infoDescription: {
        color: '#0f0f0f'
    },
    quantity: {
        color: "#32a583",
        fontWeight: "bold"
    },
    recap: {
        backgroundColor: '#fff',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
    },
    recapLine: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 5,
    },
    recapLineTitle: {
        flex: 1,
        fontSize: 15
    },
    recapLineData: {
        flex: -1,
        fontSize: 15
    },
    bold: {
        fontWeight: 'bold'
    },
    boldColor: {
        fontWeight: 'bold',
        color: '#fda127'
    },
    horizontalSeparator: {
        borderBottomColor: '#e0e3e5',
        borderBottomWidth: 1,
        marginTop: 12,
        marginBottom: 12,
    },
});
