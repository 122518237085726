import React, {Component} from 'react';
import {Platform, ImageBackground, View, ScrollView, Text} from 'react-native';
import {Button} from 'react-native-elements';
import {addOneProductToFormula, validateFormula} from '../../../core/actions/Cart';
import {connect} from 'react-redux';
import StepIndicator from 'react-native-step-indicator';
import {NavigationActions, StackActions} from 'react-navigation';
import ArticleList from '../../components/List/ArticleList';


const stepsIndicator = {
    stepIndicatorSize: 20,
    currentStepIndicatorSize: 20,
    separatorStrokeWidth: 2,
    currentStepStrokeWidth: 3,
    stepStrokeCurrentColor: '#fda127',
    stepStrokeWidth: 3,
    stepStrokeFinishedColor: '#6a236d',
    stepStrokeUnFinishedColor: '#aaaaaa',
    separatorFinishedColor: '#6a236d',
    separatorUnFinishedColor: '#aaaaaa',
    stepIndicatorFinishedColor: '#6a236d',
    stepIndicatorUnFinishedColor: '#ffffff',
    stepIndicatorCurrentColor: '#ffffff',
    stepIndicatorLabelFontSize: 13,
    currentStepIndicatorLabelFontSize: 13,
    stepIndicatorLabelCurrentColor: '#fda127',
    stepIndicatorLabelFinishedColor: '#ffffff',
    stepIndicatorLabelUnFinishedColor: '#aaaaaa',
    labelColor: '#999999',
    labelSize: 13,
    currentStepLabelColor: '#fda127',

};


import styles from './styles';
import Container from "../../components/Container";


class FormulaComposition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: null,
            formula: this.props.navigation.state.params.formula,
            compositionStep: this.props.navigation.state.params.compositionStep,
        };

        this.state.inComposingView = this.state.formula.compositions.length > this.state.compositionStep;

    }

    componentDidMount() {

    };

    componentWillUnmount() {
    };

    findProduct = (id) => {
        for (let i = 0; i < this.props.products.length; i++) {
            if (this.props.products[i].id === id) {
                return this.props.products[i];
            }
        }
    };

    onStepIndicatorPress = (position) => {

        //this.props.navigation.goBack(null);
        if (position < this.state.compositionStep) {

            this.setState(previousState => {
                return {
                    compositionStep: position,
                    inComposingView: true

                };
            });
        }
    };

    onLearnMore = (product) => {
        this.props.navigation.navigate('ProductDetails', {...{product: product}});
    };

    validateComposition = () => {

        this.props.validateFormula(this.state.formula.id);

        const resetAction = StackActions.reset({
            index: 0,
            actions: [
                NavigationActions.navigate({routeName: 'Index'})
            ]
        });
        this.props.navigation.dispatch(resetAction);
    };

    addComposition = (product) => {

        this.props.addOneProductToFormula(this.state.formula.id, this.state.formula.compositions[this.state.compositionStep].id, product.id, product.extra.amount);

        this.setState(previousState => {
            return {
                compositionStep: previousState.compositionStep + 1,
                inComposingView: previousState.formula.compositions.length > (previousState.compositionStep + 1)
            };
        });

    };

    generateStepProgressHeaders() {
        let headers = [];

        for (let i = 0; i < this.state.formula.compositions.length; i++) {
            headers[i] = this.state.formula.compositions[i].name.toUpperCase();
        }
        return headers;
    };

    isProductInFormula(product) {
        let stepProducts = this.state.formula.compositions[this.state.compositionStep].compositionProducts;

        for (let i = 0; i < stepProducts.length; i++) {
            if (product.id === stepProducts[i].idProduct) {
                return true;
            }
        }

        return false;
    }

    createProductsInFormulaList(products) {
        let productsInFormula = [];
        let updatedProduct = null;
        let stepProducts = this.state.formula.compositions[this.state.compositionStep].compositionProducts;

        for (let i = 0; i < stepProducts.length; i++) {
            for (let j = 0; j < products.length; j++) {

                if (products[j].id === stepProducts[i].idProduct) {
                    updatedProduct = Object.assign({}, products[j], {
                        extra: stepProducts[i].extra
                    });
                    productsInFormula.push(updatedProduct);
                }
            }
        }

        return productsInFormula;
    }


    _renderProductLine(key, article, extra) {
        return (
            <View key={key} style={styles.row}>
                <View style={styles.rowLeft}>
                    <Text style={styles.productQuantity}>1x</Text>
                </View>
                <View style={styles.rowCenter}>
                    <Text style={styles.productTitle}>{article.name}</Text>
                    <Text style={styles.productDescription}>{article.complementaryName}</Text>
                </View>
                <View style={styles.rowRight}>
                    {extra > 0 &&
                    <Text style={styles.rowRightBottom}>+{(extra / 100).toFixed(2)} €</Text>
                    }
                </View>

            </View>
        );
    };

    _renderFinalView(formulaInCart) {
        return (
            <ScrollView>
                {Object.keys(formulaInCart.list).map((key) => {
                    let product = this.findProduct(formulaInCart.list[key].id);
                    return this._renderProductLine(key, product, formulaInCart.list[key].extra);
                })}
            </ScrollView>
        );
    };

    _renderComposingView(products) {

        return (
            <ArticleList
                scrollOnClick={true}
                flatListRef={this.listRef}
                data={products}
                priceAttribute="extra"
                onAddPress={this.addComposition}
                onInfoPress={this.onLearnMore}/>
        );
    };

    render() {
        let products = this.props.products;

        let headers = this.generateStepProgressHeaders();
        let formulaInCart = this.props.cart.formulaCreator.formula;

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>

                <View style={styles.stepsIndicator}>
                    <StepIndicator
                        customStyles={stepsIndicator}
                        stepCount={headers.length}
                        currentPosition={this.state.compositionStep}
                        labels={headers}
                        onPress={(position) => this.onStepIndicatorPress(position)}
                    />
                </View>

                {this.state.inComposingView ?
                  this._renderComposingView(this.createProductsInFormulaList(products.filter(product => this.isProductInFormula(product))))
                :
                  <Container size={'small'}>
                    {this._renderFinalView(formulaInCart)}
                    <View style={styles.payment}>
                        <View style={styles.recap}>
                            <View style={styles.recapLine}>
                                <Text style={[styles.recapLineTitle, styles.bold]}>FORMULE</Text>
                                <Text style={[styles.recapLineData, styles.bold]}>{(formulaInCart.price / 100).toFixed(2)} €</Text>
                            </View>
                            <View style={styles.recapLine}>
                                <Text style={styles.recapLineTitle}>Extra</Text>
                                <Text style={styles.recapLineData}>{(formulaInCart.extraPrice / 100).toFixed(2)} €</Text>
                            </View>
                            <View style={styles.horizontalSeparator}/>
                            <View style={styles.recapLine}>
                                <Text style={[styles.recapLineTitle, styles.boldColor]}>TOTAL</Text>
                                <Text
                                    style={[styles.recapLineData, styles.boldColor]}>{((formulaInCart.extraPrice + formulaInCart.price) / 100).toFixed(2)}
                                    €</Text>
                            </View>
                        </View>
                        <Button
                            raised={(Platform.OS === 'ios')}
                            onPress={() => this.validateComposition()}
                            buttonStyle={styles.addButton}
                            title='AJOUTER AU PANIER'/>
                    </View>
                  </Container>
                }
            </ImageBackground>
        );
    };

}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        products: state.data.data.products,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addOneProductToFormula: (formulaId, compositionStep, productId, productPrice) => dispatch(addOneProductToFormula(formulaId, compositionStep, productId, productPrice)),
        validateFormula: (formulaId) => dispatch(validateFormula(formulaId)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormulaComposition);
