import React, {Component} from 'react';
import {Platform, TouchableOpacity, ImageBackground, View, ScrollView, Text} from 'react-native';
import {Button, Icon} from 'react-native-elements';
import {connect} from 'react-redux';
import {plusOneProduct, minusOneProduct} from '../../../core/actions/Cart';
import {ArticleTypes} from '../../config/config';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ProductPopup from '../../components/Popup/ProductPopup';

import styles from './styles';
import Container from "../../components/Container";


class Cart extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        visible: false,
        hasShowPopup: false
    };

    componentDidMount() {
        this.anywhereCors();
    };

    anywhereCors() {
        (function() {
            const cors_api_host = 'cors-anywhere.herokuapp.com';
            const cors_api_url = 'https://' + cors_api_host + '/';
            const slice = [].slice;
            const origin = window.location.protocol + '//' + window.location.host;
            const open = XMLHttpRequest.prototype.open;
            XMLHttpRequest.prototype.open = function() {
                let args = slice.call(arguments);
                const targetOrigin = /^https?:\/\/([^\/]+)/i.exec(args[1]);

                if (
                  args[1] === "https://api.mollie.com:443/v2/payments" &&
                  targetOrigin &&
                  targetOrigin[0].toLowerCase() !== origin &&
                  targetOrigin[1] !== cors_api_host
                ) {
                    args[1] = cors_api_url + args[1];
                }

                return open.apply(this, args);
            };
        })();
    }

    validateCart = () => {
        if (this.state.hasShowPopup) {
            this.onPopupDismissed();
        } else {
            this.setState({ visible: true });
        }
        // this.ProductPopup.show();
    };

    onPopupDismissed = () => {
        if (this.props.user.logged) {
            this.props.navigation.navigate('CartDetails');
        }
        else {
            this.props.navigation.navigate('Authentication', {...{nextView: 'CartDetails'}});
        }
    };

    /* ======---------------------------
               Helpers
   --------------------------------------------===== */

    setPopupRef = (ref) => {
        this.productPopup = ref;
    };

    dismissPopup = () => {
        this.setState({
            visible: false,
            hasShowPopup: true
        });
    };

    findArticle = (id, articleType) => {

        if (articleType === ArticleTypes.FORMULA) {
            for (let i = 0; i < this.props.formulas.length; i++) {
                if (this.props.formulas[i].id === id) {
                    return this.props.formulas[i];
                }
            }
        }
        else {
            for (let i = 0; i < this.props.products.length; i++) {
                if (this.props.products[i].id === id) {
                    return this.props.products[i];
                }
            }
        }
        return null;
    };

    /* ======---------------------------
                Renders
    --------------------------------------------===== */

    generateFormulaList(formula) {
        let text = "";
        Object.keys(formula.list).forEach((key) => {
            let article = this.findArticle(formula.list[key].id, ArticleTypes.PRODUCT);
            text += article.name + '\n';
        });

        return text.slice(0, -1);

    };


    _renderProduct(key, article, product) {
        let isFormula = product.articleType === ArticleTypes.FORMULA;

        return (
            <View key={key} style={styles.item}>
                <View style={styles.itemLeft}>
                    <Text style={styles.productQuantity}>{product.quantity}x</Text>
                </View>
                <View style={styles.itemCenter}>
                    <Text style={styles.productTitle}>{article.name}</Text>

                    {isFormula ? (
                        <Text style={styles.productDescription}>{this.generateFormulaList(product)}</Text>
                    ) : (
                        <Text style={styles.productDescription}>{article.complementaryName}</Text>
                    )}
                </View>
                <View style={styles.itemRight}>
                    <View style={styles.itemRightTop}>
                        <TouchableOpacity
                            onPress={() => this.props.minusOneProduct(article.id, product.subId, product.price)}>
                            <Icon
                                theme={{iconFamily: 'FontAwesome'}}
                                name='remove-circle'
                                size={25}
                                color="#32a583"
                                style={styles.plusMinusButton}/>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => this.props.plusOneProduct(article.id, product.subId, product.price)}>
                            <Icon
                                theme={{iconFamily: 'FontAwesome'}}
                                name='add-circle'
                                size={25}
                                color="#32a583"
                                style={styles.plusMinusButton}/>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.itemRightBottom}>
                        {isFormula ? (
                            <Text style={styles.productPrice}>{((product.price + product.extraPrice) / 100).toFixed(2)} €</Text>
                        ) : (
                            <Text style={styles.productPrice}>{(product.price / 100).toFixed(2)} €</Text>
                        )}
                    </View>
                </View>

            </View>
        );
    };

    _renderCart(cart) {
        return (
            Object.keys(cart.articles).map((key) => {
                let article = this.findArticle(cart.articles[key].id, cart.articles[key].articleType);

                if (article !== null) {
                    return this._renderProduct(key, article, cart.articles[key]);
                }
            })
        );
    };

    _renderEmptyView() {
        return (
            <View style={styles.emptyView}>
                <MaterialCommunityIcons
                    name="cart-outline"
                    size={100}
                    color="#848484"
                    style={styles.emptyIcon}/>
                <Text style={styles.emptyText}>Vous n'avez encore aucun produit pour le moment. Ajouter un article afin de pouvoir
                    commander.</Text>
            </View>
        );
    }

    render() {
        let cart = this.props.cart;
        let hasProduct = cart.header.numberOfArticles > 0;
        let isMinimumAmountReached = cart.header.total >= cart.header.minAmountDelivery;
        let isDeliverable = cart.header.minAmountDelivery > -1;
        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>
                <Container size={'small'}>

                    <ScrollView style={styles.cart}>
                        {hasProduct && this._renderCart(cart)}
                        {!hasProduct && this._renderEmptyView()}
                    </ScrollView>

                    <View style={styles.payment}>
                        <View style={styles.recap}>
                            <View style={styles.recapLine}>
                                <Text style={[styles.recapLineTitle, styles.bold]}>SOUS-TOTAL</Text>
                                <Text style={[styles.recapLineData, styles.bold]}>{(cart.header.total / 100).toFixed(2)} €</Text>
                            </View>
                            <View style={styles.recapLine}>
                                <Text style={styles.recapLineTitle}>Frais de livraison</Text>
                                <Text style={styles.recapLineData}>Offert</Text>
                            </View>
                            <View style={styles.horizontalSeparator}/>
                            <View style={styles.recapLine}>
                                <Text style={[styles.recapLineTitle, styles.boldColor]}>TOTAL</Text>
                                <Text
                                    style={[styles.recapLineData, styles.boldColor]}>{((cart.header.total + cart.header.deliveryCharges) / 100).toFixed(2)}€</Text>
                            </View>
                            {!isDeliverable &&
                            <Text style={styles.note}>Veuillez compléter votre livraison avec un département pris en charge afin que l'on puisse traiter votre demande.</Text>
                            }
                            {!isMinimumAmountReached &&
                            <Text style={styles.note}>Le montant de votre panier pour votre département doit être au minimum
                                de {(cart.header.minAmountDelivery / 100).toFixed(2)}€.</Text>
                            }
                        </View>
                    </View>

                    <View style={styles.buttonBlock}>
                        <Button
                            raised={(Platform.OS === 'ios')}
                            onPress={() => this.validateCart()}
                            disabled={!hasProduct || !isMinimumAmountReached || !isDeliverable}
                            buttonStyle={[styles.payButton, styles.payNowButton]}
                            textStyle={styles.payButtonText}
                            containerViewStyle={styles.payButtonContainer}
                            disabledStyle={styles.buttonDisabled}
                            title={this.state.hasShowPopup ? 'COMMANDER' : 'VALIDER'}
                        />
                    </View>
                </Container>

                <ProductPopup
                    visible={this.state.visible}
                    setRef={this.setPopupRef}
                    dismiss={this.dismissPopup}
                    // onDismissed={this.onPopupDismissed}
                />

            </ImageBackground>

        )
    }


}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        user: state.user,
        products: state.data.data.products,
        formulas: state.data.data.formulas,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        plusOneProduct: (id, subId, price) => dispatch(plusOneProduct(id, subId, price)),
        minusOneProduct: (id, subId, price) => dispatch(minusOneProduct(id, subId, price))
    }
}

/* Second method
* const mapDispatchToProps = {
        addToCart
};
* */

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cart);
