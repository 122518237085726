// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
        backgroundColor: 'transparent'
    },
    containerImage: {
        height: 200,
        width: '100%',
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
        marginTop: -30,
        marginBottom: 30,
        paddingTop: 10
    },
    title: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10
    },
    logo: {
        width: 220,
        height: 150,
        alignSelf: "center"
    },
    keyboardAvoiding: {
        flex: 1,
    },
    buttonStylePrimary: {
        backgroundColor: '#fda127',
        margin: 15,
    },
    buttonStyleSecondary: {
        backgroundColor: '#67256a',
        margin: 15,
    },
    buttonStyleTransparent: {
        backgroundColor: 'transparent',
        paddingBottom: 5,
        paddingTop: 15,
    },
    body: {
        paddingTop: 30
    },
    footer: {
        paddingBottom: 30
    },
    label: {
        color: '#d0d0d0',
        marginLeft: 15,
    },
    error: {
        backgroundColor: '#a63a32',
        padding: 10,
        color: '#fff',
        margin: 10,
        borderRadius: 6,
    },
    input: {
        margin: 15,
        paddingLeft: 10,
        paddingRight: 10,
        height: 40,
        borderColor: '#7a42f4',
        borderWidth: 1,
        backgroundColor: '#fff'
    }
});