import {
    FETCHING_DATA_PENDING,
    FETCHING_DATA_SUCCESS,
    FETCHING_DATA_FAILURE,
    FETCHING_CONFIG_PENDING,
    FETCHING_CONFIG_SUCCESS,
    FETCHING_CONFIG_FAILURE,
} from '../actions/Data'

const initialState = {
    config: [],
    data: {
        products: [],
        formulas: [],
        zones: []
    },
    error: null,
    success: false,
    maintenance: false,
    offline: false
};

export default function data(state = initialState, action) {
    // console.log(action);
    switch (action.type) {
        case FETCHING_DATA_PENDING:
            return state = updateObject(state, {
                data: [],
                error: null,
                success: false
            });
        case FETCHING_DATA_FAILURE:
            return state = updateObject(state, {
                data: [],
                error: action.error,
                success: false
            });
        case FETCHING_DATA_SUCCESS:
            return state = updateObject(state, {
                data: {
                    ...state.data,
                    [action.key]: action.data
                },
                error: null,
                success: true,
                maintenance: false,
                offline: false
            });

        case FETCHING_CONFIG_PENDING:
            return state = updateObject(state, {
                config: [],
                error: null,
            });
        case FETCHING_CONFIG_FAILURE:
            return state = updateObject(state, {
                config: [],
                error: action.error
            });
        case FETCHING_CONFIG_SUCCESS:
            return state = updateObject(state, {
                config: action.config,
                error: null
            });

        default:
            return state;
    }
};

function updateObject(myObj, value) {
    return Object.assign({}, myObj, value);
}

function updateKey(myObj, key, value) {
    return Object.assign({}, myObj[key], value);
}
