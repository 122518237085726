// @flow
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
    },
    stepsIndicator:{
        paddingTop: 10,
        backgroundColor: '#fff',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    row: {
        backgroundColor: 'white',
        marginTop: 7,
        marginBottom: 5,
        marginLeft: 7,
        marginRight: 7,
        borderColor: '#d6d7da',
        padding: 15,
        paddingBottom: 10,
        paddingTop: 8,
        flex: 1,
        flexDirection: 'row',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    rowLeft: {
        flex: -1,
        alignSelf: 'center'
    },
    rowCenter: {
        flex: 1,
        marginLeft: 16,
        alignSelf: 'center',
    },
    rowRight: {
        flex: -1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rowRightTop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rowRightBottom: {
        alignSelf: 'flex-end',
        marginTop: 6
    },
    productTitle: {
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#fda127'
    },
    productDescription: {},
    productQuantity: {
        color: '#32a583',
        fontWeight: 'bold'
    },
    recap: {
        backgroundColor: '#fff',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
        marginTop: -400
    },
    recapLine: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 5,
    },
    recapLineTitle: {
        flex: 1,
        fontSize: 15
    },
    recapLineData: {
        flex: -1,
        fontSize: 15
    },
    horizontalSeparator: {
        borderBottomColor: '#e0e3e5',
        borderBottomWidth: 1,
        marginTop: 12,
        marginBottom: 12,
    },
    bold: {
        fontWeight: 'bold'
    },
    boldColor: {
        fontWeight: 'bold',
        color: '#fda127'
    },
    buttonBlock: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    addButtonText: {
        fontSize: 14,
        textAlign: 'center'
    },
    addButtonContainer: {
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor: '#65256b',
    },
    addButton: {
        marginTop: 10,
        borderRadius: 2,
        alignSelf: 'stretch',
        backgroundColor: '#65256b',
    }
});
