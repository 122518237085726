import React from "react";
import {View} from "react-native";
import {isMobileDevice} from "../../services/responsive";

class Container extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      heightAuto: this.props.heightAuto ?? false
    }
  }

  getSize() {
    switch (this.props.size) {

      case 'small':
        return 920;

      case 'medium':
        return 1080;

      case 'big':
      default:
        return 1200;

    }
  }

  getHeight() {
    if (this.state.heightAuto) {
      return 'auto';
    } else if (isMobileDevice()) {
      return '70vh';
    }
    return '100vh';
  }

  render() {
    return (
      <View style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: this.getHeight(),
        overflowY: 'scroll'
      }}>
        <View style={{
          width: '100%',
          height: '100%',
          maxWidth: this.getSize() + 'px'
        }}>
          {this.props.children}
        </View>
      </View>
    );
  }
}

export default Container;