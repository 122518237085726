import React from 'react';
import {
    Linking,
    Modal,
    Platform,
    ScrollView,
    ImageBackground,
    View,
    TextInput,
    Text,
    TouchableOpacity,
    KeyboardAvoidingView
} from 'react-native';
import {CheckBox, Button, Icon} from 'react-native-elements';
import {connect} from 'react-redux';
import {plusOneProduct, minusOneProduct, setDetails, validateCart, clearCart, createMolliePayment} from '../../../core/actions/Cart';
import {logout} from '../../../core/actions/User';
import {CartService} from '../../../core/services';
import {NavigationActions, StackActions} from 'react-navigation';
import Loading from '../../components/Loading';
import {ApplicationManager} from '../../../core/managers';


import {PRODUCT_PIMENTO_CODE, CART_ERROR_INVALID_ARTICLE, EXPIRED_TOKEN, INVALID_TOKEN, MISSING_TOKEN} from '../../config/config.js';

import styles from './styles';
import Container from "../../components/Container";

class CartDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            termOfSales: false,
            remark: this.props.cart.details.remark,
            salt: this.props.cart.details.salt,
            cutlery: {
                wished: Math.min(this.props.cart.details.cutlery, this.props.cart.header.numberOfArticles),
                min: 0,
                max: this.props.cart.header.numberOfArticles
            },
            complementaryAddress: this.props.cart.delivery.complementary,
            loadingPromotion: false,
            loadingPayment: false,
            promoCode: {
                label: this.props.cart.details.promoCode,
                error: false,
                percent: this.props.cart.details.promoCodePercent,
                valid: false,
            },
            termsOfSalesModal: false,
        };


    }

    componentDidMount() {
    };


    setModalVisible(visible) {
        this.setState({termsOfSalesModal: visible});
    }

    goToTermsPage() {
        Linking.openURL("https://xoxotime.fr/index.php/conditions-generales")
            .catch(
                err => console.log('An error occurred', err)
            );
    }

    /* ======---------------------------
                Buttons
    --------------------------------------------===== */
    addCutlery = () => {
        if (this.state.cutlery.wished >= this.state.cutlery.max)
            return;

        this.setState(previousState => {
            return {
                cutlery: {
                    ...previousState.cutlery,
                    wished: previousState.cutlery.wished + 1
                }
            };
        });
    };

    removeCutlery = () => {
        if (this.state.cutlery.wished <= this.state.cutlery.min)
            return;

        this.setState(previousState => {
            return {
                cutlery: {
                    ...previousState.cutlery,
                    wished: previousState.cutlery.wished - 1
                }
            };
        });
    };

    addPimento = () => {
        if (this.props.pimento === null)
            return;

        this.props.plusOneProduct(this.props.pimento.id, null, this.props.pimento.price);
    };

    removePimento = () => {
        if (this.props.pimento === null || this.props.numberOfPimentos <= 0)
            return;

        this.props.minusOneProduct(this.props.pimento.id, null, this.props.pimento.price);
    };

    saveDetails = () => {

        this.props.setDetails(this.state.remark, this.state.salt, this.state.cutlery.wished, this.state.complementaryAddress,
            this.state.promoCode.label, this.state.promoCode.percent);
    };


    proceedToValidation = (payNow) => {
        this.saveDetails();

        this.setState({loadingPayment: true});

        this.props.validateCart(payNow)
            .then(() => {
                if (this.props.cart.order.id !== -1) {
                    if (payNow) {
                        this.redirectToPaymentPage();
                    }
                    else {
                        this.redirectToPaymentStatePage();
                    }
                }
                else {
                    let redirectToError = true;

                    if (this.props.cart.order.error === CART_ERROR_INVALID_ARTICLE) {

                        //TODO faire un reload ici de facon propre
                    }
                    else if (this.props.cart.order.error === EXPIRED_TOKEN || this.props.cart.order.error === INVALID_TOKEN
                        || this.props.cart.order.error === MISSING_TOKEN) {
                        ApplicationManager.saveUserData(null).done();

                        let action = StackActions.reset({
                            index: 3,
                            actions: [
                                NavigationActions.navigate({routeName: "Index"}),
                                NavigationActions.navigate({routeName: "Cart"}),
                                NavigationActions.navigate({routeName: "CartDetails"}),
                                NavigationActions.navigate({routeName: "Authentication"}),
                            ]
                        });

                        this.props.navigation.dispatch(action);
                        redirectToError = false
                    }

                    if (redirectToError) {
                        this.props.navigation.navigate('Error', {...{error: 'error.' + this.props.cart.order.error}});
                    }
                }
            });


    };

    redirectToPaymentPage = async () => {
        const postData = this.props.cart.order.paymentPostData.split("&");
        const url = await createMolliePayment(postData);

        const cart = {
            wishDeliveryDate: this.props.cart.order.wishDeliveryDate,
            id: this.props.cart.order.id,
            address: this.props.cart.order.address
        };
        this.props.navigation.navigate('Payment', {...{url, postData, cart, goToPaymentPage: true}});
    };

    redirectToPaymentStatePage = () => {
        let cart = {
            wishDeliveryDate: this.props.cart.order.wishDeliveryDate,
            id: this.props.cart.order.id,
            address: this.props.cart.order.address
        };

        const resetAction = StackActions.reset({
            index: 1,
            actions: [
                NavigationActions.navigate({routeName: 'Index'}),
                NavigationActions.navigate({routeName: 'PaymentState', params: {...{cart: cart}}}),
            ]
        });
        this.props.navigation.dispatch(resetAction);
        this.props.clearCart();
    };

    applyPromotion = () => {
        if (this.state.promoCode === null || (this.state.promoCode.label !== null && this.state.promoCode.label.length <= 0))
            return;

        this.setState({loadingPromotion: true});

        CartService.isPromotionAvailable(this.state.promoCode.label)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
            })
            .then(responseData => {

                this.setState({
                    loadingPromotion: false,
                    promoCode: {
                        label: responseData.code,
                        percent: responseData.percentage,
                        error: false,
                        valid: true,
                    }
                });
            })
            .catch(error => {
                this.setState({
                    loadingPromotion: false,
                    promoCode: {
                        label: null,
                        percent: 0,
                        error: true,
                        valid: false
                    }
                });
            });
    };


    /* ======---------------------------
                Renders
    --------------------------------------------===== */

    render() {
        let cart = this.props.cart;
        let realPrice = cart.header.total + cart.header.deliveryCharges;
        let totalPrice = realPrice * ((100 - this.state.promoCode.percent) / 100);

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>

                <View style={styles.payment}>
                    <View style={styles.recap}>
                        <View style={styles.recapLine}>
                            <Text style={[styles.recapLineTitle, styles.boldColor]}>TOTAL</Text>
                            {this.state.promoCode.valid &&
                            <Text style={[styles.recapLineData, styles.lineThrough]}>{(realPrice / 100).toFixed(2)}€</Text>
                            }
                            <Text style={[styles.recapLineData, styles.boldColor]}>{(totalPrice / 100).toFixed(2)}€</Text>
                        </View>
                    </View>
                </View>

                <KeyboardAvoidingView
                    style={styles.keyboardAvoiding}
                    behavior={(Platform.OS === 'ios') ? "padding" : null}>
                    <Container size={'medium'} heightAuto={true}>
                        <ScrollView>

                            <View style={styles.item}>
                                <Text style={styles.title}>#01 - Détails de livraison</Text>
                                <TextInput
                                    placeholder="Batiment, étage, digicode..."
                                    multiline={true}
                                    maxLength={200}
                                    blurOnSubmit={true}
                                    underlineColorAndroid="transparent"
                                    style={styles.formTextArea}
                                    onChangeText={(text) => this.setState({complementaryAddress: text})}
                                    value={this.state.complementaryAddress}
                                />
                            </View>

                            <View style={styles.item}>
                                <Text style={styles.title}>#02 - Détails de la commande</Text>
                                <TextInput
                                    placeholder="Vos remarques au restaurant..."
                                    multiline={true}
                                    numberOfLines={4}
                                    maxLength={200}
                                    blurOnSubmit={true}
                                    underlineColorAndroid="transparent"
                                    style={styles.formTextArea}
                                    onChangeText={(text) => (this.setState({remark: text}))}
                                    value={this.state.remark}
                                />

                                <View style={styles.inline}>
                                    <View style={styles.inlineChild}>
                                        <TouchableOpacity
                                            onPress={() => this.removeCutlery()}>
                                            <Icon
                                                theme={{iconFamily: 'FontAwesome'}}
                                                name='remove-circle'
                                                size={28}
                                                color="#32a583"
                                                style={styles.plusMinusButton}/>
                                        </TouchableOpacity>
                                        <Text style={styles.plusMinusFormText}>{this.state.cutlery.wished}</Text>
                                        <TouchableOpacity
                                            onPress={() => this.addCutlery()}>
                                            <Icon
                                                theme={{iconFamily: 'FontAwesome'}}
                                                name='add-circle'
                                                size={28}
                                                color="#32a583"
                                                style={styles.plusMinusButton}/>
                                        </TouchableOpacity>
                                        <Text style={styles.plusMinusFormLabel}>couverts</Text>
                                    </View>

                                    {this.props.pimento !== null &&
                                    <View style={styles.inlineChild}>
                                        <TouchableOpacity
                                            onPress={() => this.removePimento()}>
                                            <Icon
                                                theme={{iconFamily: 'FontAwesome'}}
                                                name='remove-circle'
                                                size={28}
                                                color="#32a583"
                                                style={styles.plusMinusButton}/>
                                        </TouchableOpacity>
                                        <Text style={styles.plusMinusFormText}>{this.props.numberOfPimentos}</Text>
                                        <TouchableOpacity
                                            onPress={() => this.addPimento()}>
                                            <Icon
                                                theme={{iconFamily: 'FontAwesome'}}
                                                name='add-circle'
                                                size={28}
                                                color="#32a583"
                                                style={styles.plusMinusButton}/>
                                        </TouchableOpacity>
                                        <Text style={styles.plusMinusFormLabel}>piment(s)
                                            (+{(this.props.pimento.price / 100).toFixed(2)}€)</Text>
                                    </View>
                                    }
                                </View>

                                <CheckBox
                                    checkedIcon='check-square'
                                    uncheckedIcon='square-o'
                                    checkedColor="#33a583"
                                    title='Ajouter des sachets de ketchup'
                                    style={styles.formCheckBox}
                                    textStyle={styles.formCheckBoxText}
                                    onPress={() => this.setState({salt: !this.state.salt})}
                                    checked={this.state.salt}
                                />

                            </View>

                            <View style={styles.item}>
                                <Text style={styles.title}>#03 - Code promotion</Text>
                                <View style={styles.inline}>
                                    <TextInput
                                        placeholder="Code promo"
                                        style={styles.formInput}
                                        onChangeText={(text) => this.setState({promoCode: {...this.state.promoCode, label: text}})}
                                        value={this.state.promoCode.label}
                                        returnKeyType='send'
                                        spellCheck={false}
                                        autoComplete={false}
                                        autoCorrect={false}
                                        underlineColorAndroid="transparent"
                                        onSubmitEditing={() => this.applyPromotion()}
                                    />
                                    <Button
                                        raised={false}
                                        disabled={this.state.loadingPromotion}
                                        onPress={() => this.applyPromotion()}
                                        buttonStyle={styles.standardButton}
                                        textStyle={styles.payButtonText}
                                        underlineColorAndroid="transparent"
                                        containerViewStyle={styles.payButtonContainer}
                                        title='Appliquer'/>
                                </View>
                                {this.state.promoCode.error &&
                                <Text style={styles.note}>Le code promotion utilisé n'est pas valide.</Text>
                                }
                                {this.state.promoCode.valid &&
                                <Text style={styles.note}>Une réduction de {(this.state.promoCode.percent).toFixed(2)}% a été appliquée.</Text>
                                }

                            </View>

                            <View style={styles.termsOfUse}>
                                <CheckBox
                                  checkedIcon='check-square'
                                  uncheckedIcon='square-o'
                                  checkedColor="#33a583"
                                  title="J'accepte les"
                                  style={styles.customCheckbox}
                                  textStyle={styles.customCheckboxText}
                                  onPress={() => this.setState({termOfSales: !this.state.termOfSales})}
                                  checked={this.state.termOfSales}
                                />
                                <TouchableOpacity onPress={() => this.goToTermsPage()}>
                                    <Text style={styles.textCheckbox}>conditions générales de ventes</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={{height: 40}}/>
                        </ScrollView>
                    </Container>

                    <View style={styles.buttonBlock}>
                        <Button
                            disabled={!this.state.termOfSales}
                            raised={(Platform.OS === 'ios')}
                            onPress={() => this.proceedToValidation(false)}
                            buttonStyle={[styles.payButton, styles.payAtDeliveryButton]}
                            textStyle={styles.payButtonText}
                            containerViewStyle={styles.payButtonContainer}
                            title='RÉGLER SUR PLACE'/>
                        <Button
                            disabled={!this.state.termOfSales}
                            raised={(Platform.OS === 'ios')}
                            onPress={() => this.proceedToValidation(true)}
                            buttonStyle={[styles.payButton, styles.payNowButton]}
                            textStyle={styles.payButtonText}
                            containerViewStyle={styles.payButtonContainer}
                            title='RÉGLER PAR CARTE'/>
                    </View>
                </KeyboardAvoidingView>
                {this.state.loadingPayment && <Loading/>}
            </ImageBackground>
        )
    }


}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        user: state.user,
        pimento: findPimento(state.data.data.products),
        numberOfPimentos: findNumberOfPimentos(state.cart)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clearCart: () => dispatch(clearCart()),
        plusOneProduct: (id, subId, price) => dispatch(plusOneProduct(id, subId, price)),
        minusOneProduct: (id, subId, price) => dispatch(minusOneProduct(id, subId, price)),
        setDetails: (remark, salt, cutlery, complementaryAddress, promoCode, promoCodePercent) =>
            dispatch(setDetails(remark, salt, cutlery, complementaryAddress, promoCode, promoCodePercent)),
        validateCart: (paymentOnline) => dispatch(validateCart(paymentOnline)),
        logout: () => dispatch(logout()),
    }
}

function findPimento(products) {
    let pimento = null;

    if (typeof products === 'undefined')
        return pimento;

    for (let i = 0; i < products.length; i++) {

        if (products[i].id === PRODUCT_PIMENTO_CODE) {
            pimento = {
                id: products[i].id,
                price: products[i].price.amount,
            }
        }
    }

    return pimento;
}

function findNumberOfPimentos(cart) {
    let numberOfPimentos = 0;

    if (typeof cart === 'undefined')
        return numberOfPimentos;

    Object.keys(cart.articles).forEach(function (key) {
        if (cart.articles[key].id === PRODUCT_PIMENTO_CODE) {
            numberOfPimentos = cart.articles[key].quantity;
        }
    });
    return numberOfPimentos;
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartDetails);
