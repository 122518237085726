import {Platform, AsyncStorage } from 'react-native';
import {fetchData} from "../actions/Data";

import {loginSuccess, loginFailure, getAddresses} from "../actions/User";
// import {checkManifest} from "../actions/App";
import { INVALID_TOKEN, MISSING_TOKEN, EXPIRED_TOKEN} from "../../src/config/config";
// import { URL_MANIFEST, INVALID_TOKEN, MISSING_TOKEN, EXPIRED_TOKEN} from "../../src/config/config";
// import { VersionHelper } from "../managers";

// let packageInformation = require('../../package.json');

const application = {
    initialized: true,
};


export function isAppInitialized() {
    return application.initialized;
}


export async function isApplicationFirstLaunch(clear = false) {
    if(clear) {
        await AsyncStorage.removeItem('applicationFirstLaunch');
    }

    let response = await AsyncStorage.getItem('applicationFirstLaunch');
    let firstTime = await JSON.parse(response);

    if (firstTime === "1" || firstTime === null) {
        await AsyncStorage.setItem('applicationFirstLaunch', "0");

        return true;
    }

    return false;
}


export async function saveUserData(user) {

    if(user === null) {
        return await AsyncStorage.removeItem('user');
    }
    else {
        let userData = {
            username: user.username,
            token: user.token,
            formattedName: user.formattedName,
        };
        return await AsyncStorage.setItem('user', JSON.stringify(userData));
    }
}

export async function getUserData() {

    let response = await AsyncStorage.getItem('user');
    return await JSON.parse(response);

}

// export function isApplicationConformToManifest(store) {
//     // return function (dispatch) {
//     // return await store.dispatch().then((success) => {
//     //     return dispatch(true).then((success) => {
//     //         return false;
//     //     });
//         return checkManifest(packageInformation.version, 'client_' + Platform.OS);
//     // }
// }

export async function loadData(store) {
    application.initialized = true;

    store.dispatch(fetchData());
    const user = await AsyncStorage.getItem('user');
    if(user !== null) {
        const userJson = await JSON.parse(user)
        store.dispatch(loginSuccess(userJson.username, userJson.formattedName, userJson.token));

        store.dispatch(getAddresses());

        return true;
    }
    return false;
    // const data = await this.getUserData().then((user) => {
    //     if(user !== null) {
    //         store.dispatch(loginSuccess(user.username, user.formattedName, user.token));

    //         store.dispatch(getAddresses());

    //         return true;
    //     }
    //     return false;
    // });
    // console.log(data, 'user');
    // return data;
}

export function handleError(dispatch, code, message) {


    if(message === INVALID_TOKEN || message === MISSING_TOKEN || message === EXPIRED_TOKEN) {
        dispatch(loginFailure(message));
        return true;
    }
    return false;
}
