import { API_LOGIN, API_REGISTER } from '../../src/config/config';

export function login(email, password) {

    let params = {
        username: email,
        password: password,
        grant_type: 'password'
    };

    let formBody = Object.keys(params).map(key =>
        encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    ).join('&');

    return fetch(API_LOGIN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
    });

}


export function register(params) {

    const body = JSON.stringify(params);

    return fetch(API_REGISTER, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body
    });

}

