import {API_ORDERS_URL} from '../../src/config/config';

import axios from 'axios';

/*
 * action types
 */
export const FETCHING_ORDERS = 'FETCHING_ORDERS';
export const FETCHING_ORDERS_SUCCESS = 'FETCHING_ORDERS_SUCCESS';
export const FETCHING_ORDERS_FAILURE = 'FETCHING_ORDERS_FAILURE';


/*
 * action creators
 */

export function fetchingOrderPending() {
    return {
        type: FETCHING_ORDERS
    }
}

export function fetchingOrderSuccess(data) {
    return {
        type: FETCHING_ORDERS_SUCCESS,
        list: data
    }
}

export function fetchingOrderFailure(error) {
    return {
        type: FETCHING_ORDERS_FAILURE,
        error
    }
}

export function fetchOrders() {
    return async (dispatch, getState) => {
        const {user} = getState();

        dispatch(fetchingOrderPending());

        await axios.get(API_ORDERS_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token
            }
        })
            .then(responseData => {
                if (responseData.data) {
                    dispatch(fetchingOrderSuccess(responseData.data));
                }
                else {
                    dispatch(fetchingOrderFailure(responseData.message));
                }
            })
    }
}
