import React, {Component} from 'react';
import { Platform, View, Text, FlatList, TouchableWithoutFeedback, ImageBackground, ScrollView} from 'react-native';
import {Button} from 'react-native-elements';
import {connect} from 'react-redux';
import {NavigationActions, StackActions} from 'react-navigation';
import {setDeliveryAddress, setDeliveryDatetime} from '../../../core/actions/Cart';
// import I18n from '../../i18n/i18n';
import {CONFIG_DELAY_DELIVERY, OPENING_HOURS} from '../../config/config';

import styles from './styles';
import AutoCompletePlaces from "../../components/AutoCompletePlaces/AutoCompletePlaces";
import Container from "../../components/Container";

class Delivery extends Component {

    constructor(props) {
        super(props);

        let minInterval = props.config[CONFIG_DELAY_DELIVERY];
        let minDeliverableDate = new Date();

        this.openingHours = props.config[OPENING_HOURS];

        minDeliverableDate.setTime(minDeliverableDate.getTime() + (minInterval * 60 * 1000));

        //TODO could be proper
        if (minDeliverableDate.getMinutes() > 45 && minDeliverableDate.getMinutes() < 60) {
            let numberOfMinutesForNextHour = 60 - minDeliverableDate.getMinutes();
            minDeliverableDate.setTime(minDeliverableDate.getTime() + (numberOfMinutesForNextHour * 60 * 1000));
        }
        this.minDeliverableDate = minDeliverableDate;

        // First place
        let latitude = null, longitude = null, address = null, department = null, complementary = null;
        if(this.props.delivery.latitude !== null) {
            latitude = this.props.delivery.latitude;
            longitude = this.props.delivery.longitude;
            address = this.props.delivery.address;
            department = this.props.delivery.department;
            complementary = null;
        }
        else if(this.props.user.addresses !== null && this.props.user.addresses.length > 0) {
            latitude = this.props.user.addresses[0].latitude;
            longitude = this.props.user.addresses[0].longitude;
            address = this.props.user.addresses[0].address;
            department = this.props.user.addresses[0].department;
            complementary = this.props.user.addresses[0].complementaryAddress;
        }

        let daysArray = this.generateDates();

        this.state = {
            asapPossible: this.isAsapPossible(this.props.delivery.asap),
            date: {
                asap: this.props.delivery.asap,
                day: this.props.delivery.date,
                hour: this.props.delivery.hour,
                minute: this.props.delivery.minute,
                today: true
            },
            place: {
                latitude: latitude,
                longitude: longitude,
                address: address,
                department: department,
                complementary: complementary
            },
        };

        this.state = {
            ...this.state,
            nextView: this.hasParam('nextView') ? this.props.navigation.state.params.nextView : null,
            days: daysArray,
            hours: this.generateHours(daysArray[0]),
            minutes: null,
        };

        //TODO load old addresses
    }

    hasParam(param) {
        if (typeof this.props.navigation.state.params !== 'undefined' && param in this.props.navigation.state.params) {
            return true;
        }
        return false;
    }

    componentDidMount() {
    };

    getDateName(type, index = 0) {
        const MOUNTHS = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ];
        const DAYS = [
            'Dimanche',
            'Lundi',
            'Mardi',
            'Mercredi',
            'Jeudi',
            'Vendredi',
            'Samedi',
        ];
        if (type === 'mounth') {
            return MOUNTHS[index];
        } else if (type === 'day') {
            return DAYS[index];
        } else if (type === 'today') {
            return "Aujourd'hui (" + DAYS[index] + ")";
        } else if (type === 'tomorrow') {
            return "Demain (" + DAYS[index] + ")";
        } else {
            return "NaN";
        }
    }

    /* ======---------------------------
                Data initialization
    --------------------------------------------===== */
    formatDate(date) {
        let dateObject = new Date(date + "T00:00:00");

        return this.getDateName('day', dateObject.getDay()) + ' ' + dateObject.getDate() + ' ' + this.getDateName('mounth', dateObject.getMonth());
    };

    isAsapPossible(today) {


        let now = new Date();

        if(!today) {
            return false;
        }
        else if(now.getHours() < 22 || now.getHours() > 6) {
            return false;
        }
        return true;
       /* Old way
        let opening = this.openingHours[dayId][0][0];
        let closing = this.openingHours[dayId][0][1];

        let openingHour = opening.length >= 5 ? parseInt(opening.substring(0, 2)) : 0;
        let openingMinute = opening.length >= 5 ? parseInt(opening.substring(3, 5)) : 0;

        let closingHour = closing.length >= 5 ? parseInt(closing.substring(0, 2)) : 0;
        let closingMinute = closing.length >= 5 ? parseInt(closing.substring(3, 5)) : 0;

        if(this.minDeliverableDate.getHours() > closingHour) {
            return false;
        }
        else if(this.minDeliverableDate.getHours() === closingHour && this.minDeliverableDate.getMinutes() >= closingMinute) {
            return false;
        }
        else if(this.minDeliverableDate.getHours() < openingHour) {
            return false;
        }
        else if(this.minDeliverableDate.getHours() === openingHour && this.minDeliverableDate.getMinutes() <= openingMinute) {
            return false;
        }
        else {
            return true;
        }

        */
    }



    generateDates() {
        let startDate = new Date();
        let numberOfDays = 7;
        let arrayDate = [];
        let title = '';

        let i = 0;

        let now = startDate;

        if(now.getHours() >= 23) {
            i = 1;
        }

        for (i; i <= numberOfDays; i++) {
            let date = new Date(startDate);
            date.setDate(date.getDate() + i);

            switch (i) {
                case 0:
                    title = this.getDateName("today", date.getDay());
                    break;
                case 1:
                    title = this.getDateName("tomorrow", date.getDay());
                    break;
                default:
                    title = this.getDateName('day', date.getDay());
            }

            let hours = [];

            for (let p = 0; p < this.openingHours[date.getDay()].length; p++) {
                hours.push({
                    min: this.getMinPossibleHour(i === 0, date.getDay(), p),
                    max: this.getMaxPossibleHour(date.getDay(), p)
                })
            }

            let data = {
                id: date.getFullYear()
                    + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
                    + '-' + ('0' + date.getDate()).slice(-2),
                title: title,
                today: i === 0,
                day: date.getDay(),
                hours
            };

            arrayDate.push(data);
        }

        return arrayDate;
    }

    getMinPossibleHour(today, dayId, plageId = 0) {
        let opening = this.openingHours[dayId][plageId][0];

        let openingHour = opening.length >= 5 ? parseInt(opening.substring(0, 2)) : 0;
        let openingMinute = opening.length >= 5 ? parseInt(opening.substring(3, 5)) : 0;

        if(today) {
            let delayedDate = this.minDeliverableDate;

            // if opening hour after delayed min possible date
            if(openingHour > delayedDate.getHours()) {
                // and that we are in the same hour
                if(openingHour === delayedDate.getHours() && openingMinute < delayedDate.getMinutes()) {
                    // we take the delayed minute because it's past opening time
                    return {hour: openingHour, minute: delayedDate.getMinutes()};
                }
                else {
                    return {hour: openingHour, minute: openingMinute};
                }
            }
            //if we're already past, we need min delivery time
            else {
                return {hour: this.minDeliverableDate.getHours(), minute: this.minDeliverableDate.getMinutes()};
            }
        }
        else {
            return {hour: openingHour, minute: openingMinute};
        }
    }

    getMaxPossibleHour(dayId, plageId = 0) {
        let closing = this.openingHours[dayId][plageId][1];

        let closingHour = closing.length >= 5 ? parseInt(closing.substring(0, 2)) : 0;
        let closingMinute = closing.length >= 5 ? parseInt(closing.substring(3, 5)) : 0;

        return {hour: closingHour, minute: closingMinute};
    }

    generateHours(selectedDay) {

        const hours = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
        let list = [];
        let i = 0;

        for (let p = 0; p < selectedDay.hours.length; p++) {
            while (selectedDay.hours[p].min.hour > hours[i]) {
                i++;
            }

            for (i; i < hours.length && hours[i] <= selectedDay.hours[p].max.hour; i++) {
                list.push({id: hours[i], title: hours[i] + 'h'})
            }
        }

        return list;
    }

    generateMinutes(selectedDay, selectedHour) {
        let minutes = ['00', '10', '20', '30', '40', '50'];

        let list = [];
        let i = 0, iMax = minutes.length;

        for (let p = 0; p < selectedDay.hours.length; p++) {
            if (selectedDay.hours[p].min.hour === selectedHour) {
                while (selectedDay.hours[p].min.minute > parseInt(minutes[i])) {
                    i++;
                }
            }

            if (selectedDay.hours[p].max.hour === selectedHour) {
                while (selectedDay.hours[p].max.minute < parseInt(minutes[iMax - 1])) {
                    iMax--;
                }
            }

            for (i; i < iMax; i++) {
                list.push({id: minutes[i], title: minutes[i]})
            }
        }
        return list;
    }

    getMinimumAmountDelivery(zones, department) {
        for (let i = 0; i < zones.length; i++) {
            // TODO change later
            if (zones[i].department === department) {
                return zones[i].minimum.amount;
            }
        }

        return -1;
    }

    /* ======---------------------------
                Actions
    --------------------------------------------===== */
    _selectAddress = (place) => {
        let department = null, postalCode = null;
        if ('address_components' in place) {
            postalCode = place.address_components[6].long_name;
            department = postalCode.substring(0, 2);
        }
        else {
            postalCode = place.formatted_address.match(/\d{5}/)[0];
            if(postalCode.length >= 2) {
                department = postalCode.substring(0, 2);
            }
            else {
                department = "00";
            }
        }

        this.setState(previousState => {
            return {
                place: {
                    address: place.formatted_address,
                    longitude: place.geometry.location.lng,
                    latitude: place.geometry.location.lat,
                    department
                }
            };
        });
    };

    _onResetStep(id) {


        this.setState(previousState => {
            return {
                date: {
                    day: id === 'day' ? null : previousState.date.day,
                    hour: id === 'hour' || id === 'day' ? null : previousState.date.hour,
                    minute: (id === 'minute' || id === 'hour' || id === 'day') ? null : previousState.date.minute,
                    today: id === 'day' ? true : previousState.date.today,
                    asap: false
                }
            };
        });
    };

    _onAsapSelection = () => {
        let day = this.minDeliverableDate.getFullYear()
        + '-' + ('0' + (this.minDeliverableDate.getMonth() + 1)).slice(-2)
        + '-' + ('0' + this.minDeliverableDate.getDate()).slice(-2);

        this.setState(previousState => {
            return {
                date: {
                    ...previousState.date,
                    asap: true,
                    day: day,
                    hour: this.minDeliverableDate.getHours(),
                    minute: this.minDeliverableDate.getMinutes(),
                },
            };
        });
    };

    _onDaySelection = (id) => {

        let selectedDay = this.state.days[0];

        for(let i = 0; i < this.state.days.length; i++) {
            if(this.state.days[i].id === id) {
                selectedDay = this.state.days[i];
                break;
            }
        }

        this.setState(previousState => {
            return {
                hours: this.generateHours(selectedDay),
                asapPossible: this.isAsapPossible(selectedDay.today),
                date: {
                    ...previousState.date,
                    day: id,
                    hour: null,
                    today: selectedDay.today,
                    asap: false
                },
            };
        });
    };


    _onHourSelection = (id) => {

        let selectedDay = this.state.days[0];

        for(let i = 0; i < this.state.days.length; i++) {
            if(this.state.days[i].id === this.state.date.day) {
                selectedDay = this.state.days[i];
                break;
            }
        }

        this.setState(previousState => {
            return {
                minutes: this.generateMinutes(selectedDay, parseInt(id)),
                date: {
                    ...previousState.date,
                    hour: id,
                }
            };
        });
    };

    _onMinuteSelection = (id) => {
        this.setState(previousState => {
            return {
                date: {
                    ...previousState.date,
                    minute: id,
                }
            };
        });
    };

    validateDelivery() {
        let minAmountDelivery = this.getMinimumAmountDelivery(this.props.zones, this.state.place.department);
        this.props.setDeliveryAddress(this.state.place.address, this.state.place.latitude, this.state.place.longitude, this.state.place.department, this.state.place.complementary, minAmountDelivery);
        this.props.setDeliveryDatetime(this.state.date.day, this.state.date.hour, this.state.date.minute, this.state.date.asap);

        // Going back if no next view
        if (this.state.nextView === null) {
            const resetAction = StackActions.reset({
                index: 0,
                actions: [
                    NavigationActions.navigate({routeName: 'Index'})
                ]
            });
            this.props.navigation.dispatch(resetAction);
        }
        else {
            this.props.navigation.navigate(this.state.nextView);
        }

    }

    canBeValidated() {
        if ((!this.state.date.asap && (this.state.date.day === null || this.state.date.minute === null || this.state.date.hour === null))
            || this.state.place.latitude === null) {
            return false;
        }
        return true;
    }


    /* ======---------------------------
                Renders
    --------------------------------------------===== */
    render() {
        let {asap, day, hour, minute} = this.state.date;
        let {asapPossible} = this.state;

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>
                <Container size={'small'}>
                    <View style={styles.contentAutoComplete}>
                        <Text style={styles.title}>Adresse</Text>
                        <AutoCompletePlaces
                            label="Choisissez votre adresse"
                            address={this.state.place.address}
                            onSelect={this._selectAddress}
                            selectBoxStyle={styles.blockStepNotValidated}
                            selectBoxTextStyle={styles.blockStepNotValidatedText}
                            selectedBoxStyle={styles.blockStepValidated}
                            selectedBoxTextStyle={styles.blockStepValidatedText}
                        />
                    </View>
                    <ScrollView>

                        <View style={styles.content}>

                            <Text style={styles.title}>Livraison</Text>

                            {(day === null) ? (
                                <View>
                                    <BlockList small={true} horizontal={true} data={this.state.days} onSelect={this._onDaySelection}/>
                                </View>
                            ) : (

                                <TouchableWithoutFeedback onPress={() => this._onResetStep('day')}>
                                    <View style={styles.blockStepValidated}>
                                        <Text style={styles.blockStepValidatedText}>{this.formatDate(day)}</Text>
                                    </View>
                                </TouchableWithoutFeedback>
                            )}

                            {day !== null &&
                                <View>
                                    {asap && <View>
                                        <Text style={styles.title}>Heure</Text>

                                        <TouchableWithoutFeedback onPress={() => this._onResetStep('hour')}>
                                            <View style={styles.blockStepValidated}>
                                                <Text style={styles.blockStepValidatedText}>Dès que possible</Text>
                                            </View>
                                        </TouchableWithoutFeedback>

                                    </View>}

                                    {!asap && <View>
                                        <Text style={styles.title}>Heure</Text>

                                        {asapPossible && <TouchableWithoutFeedback onPress={() => this._onAsapSelection()}>
                                            <View>
                                                {hour === null && <View style={styles.blockStepNotValidated}>
                                                    <Text style={styles.blockStepNotValidatedTextBig}>Dès que possible</Text>
                                                </View>}
                                            </View>

                                        </TouchableWithoutFeedback>}

                                        <View style={styles.hourStepValidatedRow}>

                                            {hour !== null &&
                                            <TouchableWithoutFeedback onPress={() => this._onResetStep('hour')}>
                                                <View style={[styles.blockStepValidated, styles.hourStepValidatedBlock]}>
                                                    <Text style={styles.blockStepValidatedText}>{(hour)}</Text>
                                                </View>
                                            </TouchableWithoutFeedback>
                                            }
                                            {minute !== null &&
                                            <TouchableWithoutFeedback onPress={() => this._onResetStep('minute')}>
                                                <View style={[styles.blockStepValidated, styles.hourStepValidatedBlock]}>
                                                    <Text style={styles.blockStepValidatedText}>{(minute)}</Text>
                                                </View>
                                            </TouchableWithoutFeedback>
                                            }

                                        </View>

                                        {hour === null &&
                                        <BlockList numColumns={4} data={this.state.hours} onSelect={this._onHourSelection}/>
                                        }

                                        {hour !== null && minute === null &&

                                        <View>
                                            <Text style={styles.title}>Minute</Text>
                                            <BlockList numColumns={4} data={this.state.minutes} onSelect={this._onMinuteSelection}/>
                                        </View>
                                        }
                                    </View>}
                            </View>}

                        </View>
                        <View style={styles.footer}>
                            <Button
                                disabled={!this.canBeValidated()}
                                raised={(Platform.OS === 'ios')}
                                onPress={() => this.validateDelivery()}
                                buttonStyle={styles.validateButton}
                                disabledStyle={styles.validateButtonDisabled}
                                title='CONFIRMER'/>
                        </View>
                    </ScrollView>
                </Container>
            </ImageBackground>
        );
    };

}

class BlockListItem extends React.PureComponent {
    _onPress = () => {
        this.props.onPressItem(this.props.id);
    };

    render() {
        return (
            <TouchableWithoutFeedback onPress={this._onPress}>
                <View
                    style={[styles.block, this.props.selected ? styles.blockSelected : {}]}
                    {...this.props}>

                    <Text style={[this.props.small ? styles.blockTextSmall : styles.blockText, this.props.selected ? styles.blockSelectedText : {}]}>
                        {this.props.title}
                    </Text>

                </View>
            </TouchableWithoutFeedback>)
    }
}

class BlockList extends React.PureComponent {
    state = {
        selected: (new Map())
    };

    _keyExtractor = (item, index) => item.id;

    _onPressItem = (id) => {  // updater functions are preferred for transactional updates
        this.setState((state) => {  // copy the map rather than modifying state.
            //If your want to enable multi selection, then use = new Map(selected)
            const selected = new Map();
            selected.set(id, !selected.get(id)); // toggle
            return {selected};
        });

        this.props.onSelect(id);
    };

    _renderItem = ({item}) => (
        <BlockListItem
            id={item.id}
            small={this.props.small}
            onPressItem={this._onPressItem}
            selected={!!this.state.selected.get(item.id)}
            title={item.title}/> );

    render() {
        let horizontal = this.props.horizontal;
        let numColumns = 1;
        if (!horizontal && this.props.numColumns) {
            numColumns = this.props.numColumns;
        }

        if (horizontal) {
            return (
                <FlatList
                    contentContainerStyle={{flex: 0}}
                    horizontal={true}
                    data={this.props.data}
                    extraData={this.state}
                    keyExtractor={this._keyExtractor}
                    renderItem={this._renderItem}/>
            );
        }
        else {
            return (
                <FlatList
                    contentContainerStyle={{flex: 0}}
                    numColumns={numColumns}
                    data={this.props.data}
                    extraData={this.state}
                    keyExtractor={this._keyExtractor}
                    renderItem={this._renderItem}/>
            );
        }
    }
}


function mapStateToProps(state) {
    return {
        delivery: state.cart.delivery,
        zones: state.data.data.zones,
        user: state.user,
        config: state.data.config,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setDeliveryAddress: (address, latitude, longitude, department, complementary, minAmountDelivery) => dispatch(setDeliveryAddress(address, latitude, longitude, department, complementary, minAmountDelivery)),
        setDeliveryDatetime: (date, hour, minute, asap) => dispatch(setDeliveryDatetime(date, hour, minute, asap)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Delivery);
