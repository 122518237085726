// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    cover: {
        width: '100%',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        elevation: 2,
        shadowOffset: {height: 2, width: 0},
        marginLeft: 10,
        marginRight: 10,
        height: height * 0.3,
        backgroundColor: 'transparent',
        margin: 10
    },
    coverImage: {
        resizeMode: "cover",
        borderRadius: 2,
        marginTop: 10,

    },
    coverOverlay: {
        width:'100%',
        height: '100%',
    },
    coverOverlayImage: {
        resizeMode: "stretch",
    },
    coverTop: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    coverTopLeft: {},
    coverTopRight: {
        flex: -1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    coverBottom: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        position: "relative",
    },
    coverBottomLeft: {
        flex: -1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    coverBottomRight: {
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    title: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 18,
    },
    subtitle: {
        color: '#fda127',
        fontWeight: 'bold',
        fontSize: 14,
    },
    price: {
        color: 'white',
        fontWeight: '300',
        fontSize: 36,
        marginBottom: 5,
    },
    novelty: {
        color: 'white',
        fontSize: 14,
        marginLeft: 10,
        marginTop: 15,
        padding: 5,
        backgroundColor: '#fda127',
    },
    infoIcon: {
        padding: 0,
        top: 10,
        borderTopWidth: 0,
        backgroundColor: 'rgba(40, 40, 40, 0.3)',
        borderLeftColor: 'rgba(0, 0, 0, 0)',
        borderLeftWidth: 30,
        borderRightWidth: 0,
        borderBottomColor: 'rgba(0, 0, 0, 0)',
        borderBottomWidth: 30,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 80,

    },
    infoIconIcon: {
        top: 8,
        right: 8,
    },
    shadow: {
        shadowOpacity: 0.75,
        shadowRadius: 10,
        shadowColor: 'black',
        shadowOffset: {height: 0, width: 0},
    }
});
