import React from 'react';
import {TouchableOpacity, ImageBackground, View, ScrollView, Text} from 'react-native';
import {fetchOrders} from '../../../core/actions/Order';
// import I18n from '../../i18n/i18n';
import {connect} from 'react-redux';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {ApplicationManager} from '../../../core/managers';
import {NavigationActions, StackActions} from 'react-navigation';

import Loading from '../../components/Loading';

import styles from './styles';
import Container from "../../components/Container";

class Order extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.props.fetchOrders();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.user.logged) {
            // ApplicationManager.saveUserData(null).done();

            let action = StackActions.reset({
                index: 1,
                actions: [
                    NavigationActions.navigate({routeName: "Index"}),
                    NavigationActions.navigate({routeName: "Authentication"}),
                ]
            });

            this.props.navigation.dispatch(action);
        }
    }

    /* ======---------------------------
               Actions
    --------------------------------------------===== */
    onClick(order) {
        this.props.navigation.navigate('OrderDetails', {...{order: order}});
    };


    /* ======---------------------------
               Renders
   --------------------------------------------===== */

    //TODO need util
    formatDate(date) {
        let dateObject = new Date(date);

        return dateObject.toLocaleDateString();
    };

    _renderEmptyView() {
        return (
            <View style={styles.emptyView}>
                <MaterialCommunityIcons
                    name="cart-outline"
                    size={100}
                    color="#848484"
                    style={styles.emptyIcon}/>
                <Text style={styles.emptyText}>Vous n'avez aucune commande pour le moment.</Text>
            </View>
        );
    }

    _renderOrderLine(key, order) {
        const constants = {
            STATE_PENDING_PAYMENT : "En attente de paiement",
            STATE_CANCELLED_PAYMENT : "Annulé",
            STATE_CANCELLED : "Annulé",
            STATE_INCOMPLETE : "Non valide",
            STATE_UNASSIGNED : "En attente de livraison",
            STATE_ASSIGNED : "En attente de livraison",
            STATE_DONE : "Livraison effectuée",
            PAYMENT_STATE_UNPAID : "Non payé",
            PAYMENT_STATE_PAID : "Payé",
            PAYMENT_STATE_CASHED : "Enregistré",
            PAYMENT_TYPE_ONLINE : "Paiement en ligne",
            PAYMENT_TYPE_PAYMENT_TERMINAL : "Paiement terminal",
            PAYMENT_TYPE_CASH : "Paiement sur place",
        }
        return (
            <TouchableOpacity key={key} onPress={() => this.onClick(order)}>
                <View style={styles.row}>
                    <View style={styles.rowCenter}>
                        <Text style={styles.orderTitle}>Commande</Text>
                        <Text style={styles.orderNumber}>#{order.id}</Text>
                    </View>
                    <View style={styles.rowRight}>
                        <Text style={styles.orderPrice}>{(order.final.amount / 100).toFixed(2)} €</Text>
                        <Text style={styles.orderStatus}>{constants[order.state]}</Text>
                        <Text style={styles.orderDate}>le {this.formatDate(order.wishDeliveryDate)}</Text>
                    </View>

                </View>
            </TouchableOpacity>
        );
    };

    render() {
        const loading = this.props.order.loading;
        const orders = this.props.order.list;

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>

                <Container size={'small'}>
                    <ScrollView>

                        {orders && Object.keys(orders).map((key) => {
                            return this._renderOrderLine(key, orders[key]);
                        })}

                        {(orders === null && !loading) && this._renderEmptyView()}

                    </ScrollView>
                </Container>

                {loading && <Loading/>}
            </ImageBackground>
        )
    }
}

function mapStateToProps(state) {
    return {
        order: state.order,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchOrders: () => dispatch(fetchOrders())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Order);
