import React, {Component} from 'react';
import {Platform, ImageBackground, Text, View} from 'react-native';
import {connect} from 'react-redux';

import {Button} from 'react-native-elements';
import Foundation from 'react-native-vector-icons/Foundation';

import {NavigationActions, StackActions} from 'react-navigation';

import Loading from '../../components/Loading';

// import I18n from '../../i18n/i18n';

// import {CartAPI} from '../../../core/service';
import {clearCart, checkOrderStatus, fetchOrder} from '../../../core/actions/Cart';

import styles from './styles';
import Container from "../../components/Container";

class PaymentState extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            clearHistory: false,
            canBePending: this.props.navigation.state.params.canBePending,
            orderId: this.props.navigation.state.params.cart.id ?? this.props.navigation.getParam('cart'),
            cart: {}
        };

    }

    componentDidMount() {
        window.setTimeout(
          () => {
              this.checkStatus(this.state.orderId);
              this.getOrderInfo(this.state.orderId)
          },
          10
        )
    };

    getDateName(type, index = 0) {
        const MOUNTHS = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ];
        const DAYS = [
            'Dimanche',
            'Lundi',
            'Mardi',
            'Mercredi',
            'Jeudi',
            'Vendredi',
            'Samedi',
        ];
        if (type === 'mounth') {
            return MOUNTHS[index];
        } else if (type === 'day') {
            return DAYS[index];
        } else if (type === 'today') {
            return "Aujourd'hui";
        } else if (type === 'tomorrow') {
            return "Demain";
        } else {
            return "NaN";
        }
    }


    formatDate(date) {
        let dateObject = new Date(date);

        return this.getDateName('day', dateObject.getDay()) + ' ' + dateObject.getDate() + ' ' + this.getDateName('mounth', dateObject.getMonth())
            + ' à ' + ('0' + dateObject.getHours()).slice(-2) + ':' + ('0' + dateObject.getMinutes()).slice(-2)
    };

    checkStatus(orderId) {
        this.props.checkOrderStatus(orderId)
            .then(responseData => {
                let isOrderValid = false;

                if(responseData.data.paymentOnline) {
                    isOrderValid = responseData.data.payed;
                }
                else {
                    isOrderValid = true;
                }

                this.setState({
                    loading: false,
                    success: isOrderValid,
                    clearHistory: isOrderValid
                });

                if(isOrderValid) {
                    this.props.clearCart();
                }

            })
            .catch(error => {
                this.props.clearCart();
                this.setState({
                    loading: false,
                    success: false,
                    clearHistory: false
                });
            });

    };

    getOrderInfo() {
        this.props.fetchOrder(this.state.orderId)
          .then(responseData => {

              this.setState({
                  loading: false,
                  cart: responseData.data,
                  // clearHistory: true
              });
          })
          .catch(error => {
              this.props.clearCart();
              this.setState({
                  loading: false,
                  success: false,
                  clearHistory: false
              });
          });
    }

    /* ======---------------------------
              Actions
    --------------------------------------------===== */
    validate() {
        this.doNextPageAction();
    };

    cancel() {
        this.props.clearCart();
        this.doNextPageAction();
    };

    back() {
        this.doNextPageAction();
    };

    doNextPageAction() {
        if(this.state.clearHistory) {
            const resetAction = StackActions.reset({
                index: 0,
                actions: [
                    NavigationActions.navigate({routeName: 'Index'}),
                ]
            });
            this.props.navigation.dispatch(resetAction);
        }
        else {
            this.props.navigation.goBack();
        }
    }


    /* ======---------------------------
                  Renders
      --------------------------------------------===== */
    render() {
        if(this.state.loading) {
            return <Loading/>;
        }
        else if(this.state.success){
            return this.renderSuccessView(this.state.cart);
        }
        else if(this.state.canBePending) {
            return this.renderWarningView();
        }
        else {
            return this.renderFailureView();
        }

    }

    renderSuccessView(cart) {
        return (
            <View
                style={styles.successContainer}>

                <ImageBackground style={styles.overlay}
                                 source={require('../../resources/img/transparent-pattern.png')}>
                    <Text style={styles.title}>Votre commande a bien été prise en compte.</Text>
                </ImageBackground>

                <View style={styles.footer}>
                    <Container size={'small'} heightAuto={true}>
                        <Text style={styles.infoTitle}>Numéro de commande</Text>
                        <Text style={styles.infoDescription}>#{cart.id}</Text>
                        <Text style={styles.infoTitle}>Livraison prévue le</Text>
                        <Text style={styles.infoMain}>{this.formatDate(cart.wishDeliveryDate)}</Text>
                        <Text style={styles.infoTitle}>Adresse</Text>
                        <Text style={styles.infoDescription}>{cart.address}</Text>
                        <View style={styles.space}/>
                        <Button
                            raised={(Platform.OS === 'ios')}
                            onPress={() => this.validate()}
                            buttonStyle={styles.button}
                            title="Retour vers l'accueil"
                        />
                    </Container>
                </View>

            </View>
        )
    }

    renderWarningView() {
        return (
            <View
                style={styles.warningContainer}>

                <ImageBackground style={styles.overlay}
                                 source={require('../../resources/img/transparent-pattern.png')}>
                    <Foundation style={styles.icon}
                                size={70}
                                name="refresh"
                                color={'#fff'} />
                    <Text style={styles.title}>En attente de confirmation</Text>
                    <Text style={styles.message}>Nous sommes en attente de confirmation de la part de votre banque.
                    Vérifier l'état de votre commande dans la liste de vos commandes.</Text>
                </ImageBackground>

                <View style={styles.footer}>
                    <Container size={'small'} heightAuto={true}>
                        <Button
                            raised={(Platform.OS === 'ios')}
                            buttonStyle={styles.button}
                            onPress={() => this.cancel()}
                            title="Retourner à l'accueil"/>
                    </Container>
                </View>

            </View>
        )
    }


    renderFailureView() {
        return (
            <View
                style={styles.errorContainer}>

                <ImageBackground style={styles.overlay}
                                 source={require('../../resources/img/transparent-pattern.png')}>
                    <Foundation style={styles.icon}
                                size={70}
                                name="alert"
                                color={'#fff'} />
                    <Text style={styles.title}>Commande non validée</Text>
                    <Text style={styles.message}>Un problème est survenu lors de votre commande.
                        Si le problème persiste merci de nous contacter.</Text>
                </ImageBackground>

                <View style={styles.footer}>
                    <Container size={'small'} heightAuto={true}>
                        <Button
                            raised={(Platform.OS === 'ios')}
                            buttonStyle={styles.button}
                            onPress={() => this.cancel()}
                            title='Annuler'/>
                        <Button
                            buttonStyle={styles.cancelButton}
                            textStyle={styles.cancelButtonText}
                            onPress={() => this.back()}
                            title='Retour'/>
                    </Container>
                </View>

            </View>
        )
    }
}


function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clearCart: () => dispatch(clearCart()),
        checkOrderStatus: (orderId) => dispatch(checkOrderStatus(orderId)),
        fetchOrder: (orderId) => dispatch(fetchOrder(orderId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentState);
