// import {INVALID_TOKEN, MISSING_TOKEN, EXPIRED_TOKEN} from "../../src/config/config";

import {setWorking, setMaintenanceMode, setOfflineMode} from '../actions/App';
import {loginFailure} from "../actions/User";


export default function decoratedFetch(dispatch, url, params = {}) {
    let errorLevel = 0;
    return fetch(url, params)
        .then(response => {

            //First we check the code
            if (response.status >= 200 && response.status < 300) {
                dispatch(setWorking());
                errorLevel = 0;
                return response.json();
            }
            else if (response.status === 503) {

                dispatch(setMaintenanceMode());
                errorLevel = 2;
                return response.json();
            }
            else {
                errorLevel = 1;
                return response.json();
            }
        })
        .then(responseData => {
            let errors = responseData !== null && 'errors' in responseData ? responseData.errors : null;

            //Now we check the message
            if(errorLevel === 2) {
                return {success: false, message: "DEFAULT_ERROR", errors: errors};
            }
            else if(errorLevel === 1) {
                if(responseData.message === "INVALID_TOKEN" || responseData.message === "MISSING_TOKEN" || responseData.message === "EXPIRED_TOKEN") {
                    dispatch(loginFailure(responseData.message, errors));
                    return {success: false, message: responseData.message, errors: errors};
                }
                else {
                    return {success: false, message: responseData.message, errors: errors};
                }
            }
            else {
                //Finally this is where it's working
                return {success: true, message: null, data: responseData};
            }
        })
        .catch(error => {
            return {success: false, message: null, errors: null};
        })
}