import { SET_WORKING, SET_MAINTENANCE, SET_OFFLINE, SET_OUTDATED} from '../actions/App';

const initialState = {
    maintenance: false,
    offline: false,
    outdated: false
};

export default function app(state = initialState, action) {
    switch (action.type) {

        case SET_WORKING:
            return state = updateObject(state, {
                maintenance: false,
                offline: false,
                outdated: false,
            });

        case SET_MAINTENANCE:
            return state = updateObject(state, {
                maintenance: true,
            });

        case SET_OFFLINE:
            return state = updateObject(state, {
                offline: true
            });

        case SET_OUTDATED:
            return state = updateObject(state, {
                outdated: true
            });


        default:
            return state;
    }
};

function updateObject(myObj, value) {
    return Object.assign({}, myObj, value);
}

function updateKey(myObj, key, value) {
    return Object.assign({}, myObj[key], value);
}
