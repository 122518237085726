// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        width: null,
        height: null,
    },
    empty: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 20,
        paddingRight: 20,
    },
    row: {
        backgroundColor: 'white',
        marginTop: 7,
        marginBottom: 5,
        marginLeft: 7,
        marginRight: 7,
        borderColor: '#d6d7da',
        padding: 15,
        paddingBottom: 10,
        paddingTop: 8,
        flex: 1,
        flexDirection: 'row',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    rowLeft: {
        flex: -1,
        alignSelf: 'center'
    },
    rowCenter: {
        flex: 1,
        alignSelf: 'center',
    },
    rowRight: {
        flex: -1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rowRightTop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rowRightBottom: {
        alignSelf: 'flex-end',
        marginTop: 6
    },
    orderTitle: {
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 'bold',
    },
    orderNumber: {
        fontSize: 20,
        fontWeight: '300',
    },
    orderPrice: {
        textAlign: 'right',
        color: '#fda127',
        fontWeight: 'bold',
        fontSize: 14,
    },
    orderStatus: {
        textAlign: 'right',
        //color: '#32a583',
        fontSize: 11,
    },
    orderDate: {
        textAlign: 'right',
        fontSize: 11,
    },
    emptyView: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignContent: 'center'
    },
    emptyText: {
        color: "#848484",
        textAlign: 'center'
    },
    emptyIcon: {
        marginTop: 20,
        alignSelf: 'center',
        /*transform: [
            {rotateX: '180deg'},
            {rotateY: '180deg'},
        ], */
    }
});
