/*
* @flow
*
* A number button matrix
*/
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native';

import {connect} from 'react-redux';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import React from 'react';
import {isMobileDevice} from "../../../services/responsive";

const handleButton = (props) => {
  props.navigation.navigate('Delivery');
};

let styles = StyleSheet.create({
  view: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 50
  },
  text: {
    color: 'white'
  },
  icon: {
    bottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  miniature: {
    position: 'absolute',
    right: 2,
    bottom: 0,
    fontWeight: 'bold',
    overflow: 'hidden',
    textAlign: 'center',
  }
});

const DeliveryButton = (props) => {
  const getIcon = valid => valid ? "check" : "close";
  const getColor = valid => valid ? "white" : "#d7473f";

  const getHour = () => {
    if (props.delivery.hour === null || props.delivery.minute === null) {
      return null;
    }

    return props.delivery.hour + ":" + props.delivery.minute;
  }

  if (isMobileDevice()) {
    return (
      <View>
        {props.data.success &&
        <TouchableOpacity onPress={() => {
          handleButton(props)
        }}>
          <MaterialCommunityIcons
            name="map-marker"
            size={24}
            color="white"
            style={styles.icon}
          />

          <MaterialCommunityIcons
            name={getIcon(props.delivery.valid)}
            size={14}
            color={getColor(props.delivery.valid)}
            style={styles.miniature}
          />

        </TouchableOpacity>
        }
      </View>
    );
  }

  const addressValid = props.delivery.address !== null;
  const hourValid = props.delivery.hour !== null;

  const address = props.delivery.address ?? 'Votre adresse ?';
  const hour = getHour() ?? 'Date et heure de livraison ?';

  return (
    <View>
      {props.data.success &&
        <TouchableOpacity
          onPress={() => {
            handleButton(props)
          }}
          style={styles.view}
        >
          <View style={{position: 'relative'}}>
            <MaterialCommunityIcons
              name="map-marker"
              size={24}
              color="white"
              style={styles.icon}
            />

            <MaterialCommunityIcons
              name={getIcon(addressValid)}
              size={14}
              color={getColor(addressValid)}
              style={styles.miniature}
            />
          </View>

          <Text style={styles.text}>{address}</Text>

          <View style={{position: 'relative'}}>
            <MaterialCommunityIcons
              name="clock"
              size={24}
              color="white"
              style={styles.icon}
            />

            <MaterialCommunityIcons
              name={getIcon(hourValid)}
              size={14}
              color={getColor(hourValid)}
              style={styles.miniature}
            />
          </View>

          <Text style={styles.text}>{hour}</Text>

        </TouchableOpacity>
      }
    </View>
  );
};

function mapStateToProps (state) {
  return {
    delivery: state.cart.delivery,
    data: state.data,
  }
}

function mapDispatchToProps (dispatch) {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryButton);
