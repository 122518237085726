import React, {Component} from 'react';
import {ImageBackground, View, ScrollView, Text} from 'react-native';
// import I18n from '../../i18n/i18n';

import styles from './styles';
import Container from "../../components/Container";

const API_PAYMENT = {
    PAYMENT_STATE_UNPAID: "PAYMENT_STATE_UNPAID",
    STATE_CANCELLED: "STATE_CANCELLED_PAYMENT",
    PAYMENT_TYPE_ONLINE: "PAYMENT_TYPE_ONLINE",
};

const DATE = {
    today: 'Aujourd\'hui',
    tomorrow: 'Demain',
    day0: 'Dimanche',
    day1: 'Lundi',
    day2: 'Mardi',
    day3: 'Mercredi',
    day4: 'Jeudi',
    day5: 'Vendredi',
    day6: 'Samedi',
    month0: 'Janvier',
    month1: 'Février',
    month2: 'Mars',
    month3: 'Avril',
    month4: 'Mai',
    month5: 'Juin',
    month6: 'Juillet',
    month7: 'Août',
    month8: 'Septembre',
    month9: 'Octobre',
    month10: 'Novembre',
    month11: 'Décembre',
}

class OrderDetails extends Component {

    UNSAFE_componentWillReceiveProps(nextProps) {
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    };


    /* ======---------------------------
               Renders
   --------------------------------------------===== */

    formatDate(date) {
        if (date === null) {
            return "";
        }
        let dateObject = new Date(date);

        return (DATE.day + dateObject.getDay()) + ' ' + dateObject.getDate() + ' ' + (DATE.month + dateObject.getMonth())
            + ' à ' + ('0' + dateObject.getHours()).slice(-2) + ':' + ('0' + dateObject.getMinutes()).slice(-2);
    };

    renderProduct(key, quantity, product, price) {
        let realKey = "p" + key;
        return (
            <View key={realKey} style={styles.article}>
                <View style={styles.horizontalSeparator}/>
                <View style={styles.inline}>
                    <Text style={styles.inlineLeft}>
                        <Text style={styles.quantity}>{quantity}x</Text> <Text style={styles.infoTitle}>{product}</Text>
                    </Text>
                    {price === 0 &&
                        <Text style={styles.inlineRight}>Offert</Text>
                    }
                </View>
            </View>
        )
    };

    renderFormula(key, formula) {
        let realKey = "f" + key;
        let formattedCompositions = "";
        for (let i = 0; i < formula.composition.length; i++) {
            formattedCompositions += formula.composition[i].nameProduct + ", ";
        }

        return (
            <View key={realKey} style={styles.article}>
                <View style={styles.horizontalSeparator}/>
                <Text>
                    <Text style={styles.quantity}>{formula.quantity}x</Text> <Text style={styles.infoTitle}>{formula.name}</Text>
                </Text>
                <Text>{formattedCompositions}</Text>
            </View>
        )
    };

    render() {
        const order = this.props.navigation.state.params.order;
        const orderPaymentTypeMessage = order.paymentType === API_PAYMENT.PAYMENT_TYPE_ONLINE ? 'En ligne' : 'Sur place';
        let orderPaymentStateMessage;

        if(order.complementaryAddress !== null && order.complementaryAddress.length <= 0) {
            order.complementaryAddress = null;
        }

        if (order.state === API_PAYMENT.STATE_CANCELLED) {
            orderPaymentStateMessage = 'Annulé';
        }
        else {
            orderPaymentStateMessage = order.paymentState === API_PAYMENT.PAYMENT_STATE_UNPAID ? 'En attente' : 'Payé';
        }

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>

                <Container size={'small'}>
                    <ScrollView>

                        <View style={styles.block}>
                            <Text style={styles.infoTitle}>Numéro de commande</Text>
                            <Text style={styles.infoMain}>#{order.id}</Text>
                            <Text style={styles.infoTitle}>Livraison</Text>
                            <Text style={styles.infoDescription}>{this.formatDate(order.wishDeliveryDate)}</Text>
                            <Text style={styles.infoTitle}>Adresse</Text>
                            <Text style={styles.infoDescription}>{order.address}</Text>
                            {order.complementaryAddress && <Text style={styles.infoDescription}>{order.complementaryAddress}</Text>}
                            <Text style={styles.infoTitle}>État du paiement</Text>
                            <Text style={styles.infoSubMain}>{orderPaymentTypeMessage + ' - ' + orderPaymentStateMessage}</Text>
                            <View style={styles.space}/>
                        </View>


                        <View style={styles.block}>
                            <Text style={styles.mainTitle}>Panier</Text>
                            {
                                Object.keys(order.formulas).map((key) => {
                                    return this.renderFormula(key, order.formulas[key]);
                                })
                            }
                            {
                                Object.keys(order.products).map((key) => {
                                    return this.renderProduct(key, order.products[key].quantity, order.products[key].name, order.products[key], order.products[key].price.amount);
                                })
                            }
                            {order.cutlery > 0 && this.renderProduct("cutlery", order.cutlery, "Couverts", 0)}
                            {order.salt > 0 && this.renderProduct("salt", order.salt, "Ketchup", 0)}
                        </View>

                        <View style={styles.block}>
                            <Text style={styles.mainTitle}>Remarque</Text>
                            <Text style={styles.infoDescription}>{order.remark}</Text>
                        </View>

                        <View style={styles.block}>
                            <View style={styles.recapLine}>
                                <Text style={[styles.recapLineTitle, styles.bold]}>SOUS-TOTAL</Text>
                                <Text style={[styles.recapLineData, styles.bold]}>{(order.total.amount / 100).toFixed(2)} €</Text>
                            </View>
                            <View style={styles.recapLine}>
                                <Text style={styles.recapLineTitle}>Réduction</Text>
                                <Text style={styles.recapLineData}>{(order.discount.amount / 100).toFixed(2)} €</Text>
                            </View>
                            <View style={styles.horizontalSeparator}/>
                            <View style={styles.recapLine}>
                                <Text style={[styles.recapLineTitle, styles.boldColor]}>TOTAL</Text>
                                <Text
                                    style={[styles.recapLineData, styles.boldColor]}>{(order.final.amount / 100).toFixed(2)} €</Text>
                            </View>
                        </View>
                    </ScrollView>
                </Container>
            </ImageBackground>
        )
    }
}


export default OrderDetails;
