// @flow
import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    stretch: {
        width: '75%',
        height: 150,
        // marginTop: 10,
    },
    androidLogo: {
        width: '100%',
        height: 200,
        marginBottom: 20
    },
    banner: {
        backgroundColor: '#67256a',
        flexDirection: 'row',
        padding: 15,
        paddingTop: 12,
        paddingBottom: 12,
        marginTop: 30,
        marginBottom: 35,
        height: 70,
        width: 230
    },
    avatar: {
        width: 33,
        height: 28,
        flex: -1,
        marginTop: 3
    },
    logout: {
        flex: -1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: -10
    },
    bannerCenter: {
        flex: 1,
        marginLeft: 12,
    },
    bannerTextBold: {
        fontWeight: 'bold',
        color: '#fff',
    },
    bannerText: {
        color: '#fff',
    },
    container: {
        height: Platform.OS === 'android' ? 1000 : 169,
        width: 300,
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    containerImage: {
        height: 169,
        width: 300,
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
        marginBottom: 200,
        marginTop: -15
    },
    items: {
        // marginBottom: Platform.OS === 'android' ? -500 : 0
        backgroundColor: 'transparent'
    }
});
