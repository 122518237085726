import {
    ArticleTypes,
    MOLLIE_API_KEY,
    // PAYMENT_URL,
    API_VALIDATE_CART_URL,
    // API_PROMOTION_CODE_URL,
    API_ORDER_STATUS,
    // URL_PAYMENT_SUCCESS,
    // URL_PAYMENT_UNCERTAIN,
    API_VALIDATE_CART_PAYMENT,
    API_SET_ID_PAYMENT,
    API_ORDER_IS_PAYED,
    API_ORDER
} from '../../src/config/config';
import { CartService } from '../services';
// import {ApplicationManager} from '../managers';
import axios from 'axios';

import createMollieClient from 'mollie-api-node-react-native';

/*
 * action types
 */

export const PLUS_ONE_PRODUCT = 'PLUS_ONE_PRODUCT';
export const MINUS_ONE_PRODUCT = 'MINUS_ONE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const PREPARE_FORMULA = 'PREPARE_FORMULA';
export const VALIDATE_FORMULA = 'VALIDATE_FORMULA';
export const PLUS_ONE_PRODUCT_TO_FORMULA = 'PLUS_ONE_PRODUCT_TO_FORMULA';
export const CLEAR_FORMULA = 'CLEAR_FORMULA';
export const CLEAR_CART = 'CLEAR_CART';
export const SET_DELIVERY_DATETIME = 'SET_DELIVERY_DATETIME';
export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const SET_DETAILS = 'SET_DETAILS';
export const VALIDATE_CART_SUCCESS = 'VALIDATE_CART_SUCCESS';
export const VALIDATE_CART_FAILURE = 'VALIDATE_CART_FAILURE';

const mollieClient = createMollieClient({ apiKey: MOLLIE_API_KEY });
/*
 * action creators
 */

export function plusOneProduct(id, subId, price) {
    return {
        type: PLUS_ONE_PRODUCT,
        id: id,
        subId: subId,
        price: price,
        articleType: ArticleTypes.PRODUCT
    }
}

export function minusOneProduct(id, subId, price) {
    return {
        type: MINUS_ONE_PRODUCT,
        id: id,
        subId: subId,
        price: price,
        articleType: ArticleTypes.PRODUCT
    }
}

export function prepareFormula(id, price) {
    return {
        type: PREPARE_FORMULA,
        id: id,
        price: price,
        articleType: ArticleTypes.FORMULA
    }
}

export function validateFormula(id) {
    return {
        type: VALIDATE_FORMULA,
        id: id,
        articleType: ArticleTypes.FORMULA
    }
}

export function addOneProductToFormula(formulaId, compositionStep, productId, extra) {
    return {
        type: PLUS_ONE_PRODUCT_TO_FORMULA,
        id: formulaId,
        compositionStep: compositionStep,
        productId: productId,
        price: extra,
        articleType: ArticleTypes.FORMULA
    }
}


export function setDetails(remark, salt, cutlery, complementaryAddress, promoCode, promoCodePercent) {
    return {
        type: SET_DETAILS,
        remark: remark,
        salt: salt,
        cutlery: cutlery,
        complementaryAddress: complementaryAddress,
        promoCode: promoCode,
        promoCodePercent: promoCodePercent,
    }
}

export function removeProduct(id, subId) {
    return {
        type: REMOVE_PRODUCT,
        id: id,
        subId: subId,
    }
}

export function clearFormula(id, articleType) {
    return {
        type: CLEAR_FORMULA,
        id: id,
        articleType: articleType
    }
}

export function clearCart() {
    return {
        type: CLEAR_CART
    }
}

export function setDeliveryDatetime(date, hour, minute, asap) {
    return {
        type: SET_DELIVERY_DATETIME,
        date: date,
        hour: hour,
        minute: minute,
        asap: asap,
    }
}

export function setDeliveryAddress(address, latitude, longitude, department, complementary, minAmountDelivery) {
    return {
        type: SET_DELIVERY_ADDRESS,
        address: address,
        latitude: latitude,
        longitude: longitude,
        department: department,
        complementary: complementary,
        minAmountDelivery: minAmountDelivery
    }
}

export function validateCartSuccess(id, paymentOnline, address, wishDeliveryDate, paymentUrl, paymentPostData) {
    return {
        type: VALIDATE_CART_SUCCESS,
        id: id,
        paymentOnline: paymentOnline,
        address: address,
        wishDeliveryDate: wishDeliveryDate,
        paymentUrl: paymentUrl,
        paymentPostData: paymentPostData
    }
}

export function validateCartFailure(paymentOnline, error) {
    return {
        type: VALIDATE_CART_FAILURE,
        paymentOnline: paymentOnline,
        error: error
    }
}

export function validateCart(paymentOnline) {

    return async (dispatch, getState) => {
        const {cart, user} = getState();
        let formattedCart = CartService.formatData(cart, paymentOnline);
        // console.log(JSON.stringify(formattedCart));
        return await axios.post(API_VALIDATE_CART_URL, JSON.stringify(formattedCart), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token
            },
        })
        .then(responseData => {
            if (responseData.status >= 200 && responseData.status < 300) {
                if (paymentOnline) {
                    dispatch(validateCartSuccess(responseData.data.id, paymentOnline, responseData.data.address, responseData.data.wishDeliveryDate, responseData.data.url, responseData.data.postData));
                }
                else {
                    dispatch(validateCartSuccess(responseData.data.id, paymentOnline, responseData.data.address, responseData.data.wishDeliveryDate, null, null));
                }
            }
            else {
                dispatch(validateCartFailure(paymentOnline, responseData.message));
            }
        })
        .catch(e => console.log(e));
    }

}

export function checkOrderStatus(orderId) {

    return async (dispatch, getState) => {
        const {user} = getState();

        let orderStatusURL = API_ORDER_STATUS.replace("{{orderId}}", orderId);

        return await axios.get(orderStatusURL, {
            headers: {
                'Authorization': 'Bearer ' + user.token
            },
        })
        .then(responseData => {
            return responseData;
        })
    }

}

export function fetchOrder(orderId) {

    return async (dispatch, getState) => {
        const {user} = getState();

        let orderStatusURL = API_ORDER.replace("{{orderId}}", orderId);

        return await axios.get(orderStatusURL, {
            headers: {
                'Authorization': 'Bearer ' + user.token
            },
        })
        .then(responseData => {
            return responseData;
        })
    }

}

function getData(postData) {

    return {
        "amount": postData[0].split("=")[1],
        "currency": postData[1].split("=")[1],
        "language": postData[2].split("=")[1],
        "LOGO": postData[3].split("=")[1],
        "orderID": postData[4].split("=")[1],
        "PSPID": postData[5].split("=")[1],
        "TP": postData[6].split("=")[1],
        "SHASIGN": postData[7].split("=")[1],
    }
}

export function createMolliePayment(data) {
        const baseUrl = window.location.protocol + '//' + window.location.host;

        const amount = `${(data[0].split("=")[1] / 100).toFixed(2)}`;
        const orderId = `${data[4].split("=")[1]}`;

        return mollieClient.payments.create({
            amount: {
                value: amount,
                currency: 'EUR'
            },
            description: orderId,
            metadata: getData(data),
            redirectUrl: baseUrl + '/#/Payment?data=' + JSON.stringify(getData(data)),
            // redirectUrl: baseUrl + '/#/PaymentState?cart=' + orderId + '&success=true',
            // webhookUrl: API_VALIDATE_CART_PAYMENT
        })
          .then(async payment => {
            await setPaymentIdToCart(orderId, payment.id);

            return payment.getPaymentUrl();
          })
          .catch(e => console.log(e));
}

export async function paymentIsPayed(orderId) {
        // return mollieClient.payments.get(payId)
        //   .then(payment => payment.isPaid())
        //   .catch(e => console.log(e));
    return await axios.get(API_ORDER_IS_PAYED.replace('{{orderId}}', orderId))
      .then(response => response.data)
      .catch(e => console.log(e));
}

async function setPaymentIdToCart(orderId, payId) {
    return await axios.put(API_SET_ID_PAYMENT.replace('{{orderId}}', orderId),{payId})
      .then(response => response)
      .catch(e => console.log(e));
}

export async function validatePayment(postData) {
    return await axios.post(API_VALIDATE_CART_PAYMENT, postData)
    .then(response => response)
    .catch(e => console.log(e));
}

//TODO do promo code and check order in proper way