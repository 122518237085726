import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import {ImageBackground } from 'react-native';
import ArticleList from '../../components/List/ArticleList';
import {LoadingWithBackground} from '../../components/Loading';

import {ApplicationManager, NavigationManager} from '../../../core/managers';
import {ArticleTypes} from '../../config/config';
import {plusOneProduct, prepareFormula, clearCart} from '../../../core/actions/Cart';
import {connect} from 'react-redux';
import {NavigationActions, StackActions} from 'react-navigation';


import styles from './styles';

class Product extends React.Component {

    constructor(props) {
        super(props);

        ApplicationManager.isApplicationFirstLaunch().then(firstTime => {
            if (firstTime) {
                this.props.navigation.navigate('Tutorial');
            }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Here we manage the state of the application. Only the first page do that
        if (this.props.main) {
            if (ApplicationManager.isAppInitialized()) {

                let redirectionKey = null;
                if (nextProps.app.maintenance) {
                    redirectionKey = 'MAINTENANCE';
                }

                if (nextProps.app.outdated) {
                    redirectionKey = 'OUTDATED';
                }

                if (nextProps.app.offline) {
                    //TODO
                }

                if(redirectionKey !== null) {
                    const resetAction = StackActions.reset({
                        index: 0,
                        actions: [
                            NavigationActions.navigate({routeName: 'Error',
                                params:{...{key: redirectionKey, error: 'error.' + redirectionKey}} })
                        ]
                    });
                    this.props.navigation.dispatch(resetAction);
                }
            }
        }
    }

    componentDidMount() {

    };

    componentWillUnmount() {

    };

    onLearnMore = (article) => {
        this.props.navigation.navigate('ProductDetails', {...{product: article}});
    };


    onArticleAdd = (article) => {
        if (this.props.articleType === ArticleTypes.FORMULA) {
            this.props.prepareFormula(article.id, article.price.amount);
            this.props.navigation.navigate('FormulaComposition', {...{formula: article, compositionStep: 0}});
        }
        else {
            this.props.plusOneProduct(article.id, null, article.price.amount);
        }
    };


    renderLoadedView(articles) {
        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>

                <ArticleList data={articles}
                             onAddPress={this.onArticleAdd}
                             onInfoPress={this.onLearnMore}/>

            </ImageBackground>
        );
    };

    render() {
        let categoryId = this.props.categoryId;
        let articles = this.props.articleType === 'formulas' ? this.props.data.data.formulas : this.props.data.data.products;

        let filteredArticles = null;

        if (!this.props.data.success || typeof articles === 'undefined' || articles.length <= 0) {
            return <LoadingWithBackground/>;
        }
        else {
            filteredArticles = articles.filter(article => categoryId === null || (article.category !== null && article.category.code === categoryId));
        }

        return this.renderLoadedView(filteredArticles);
    }

}


function mapStateToProps(state) {
    return {
        data: state.data,
        user: state.user,
        app: state.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        plusOneProduct: (id, subId, price) => dispatch(plusOneProduct(id, subId, price)),
        prepareFormula: (id, price) => dispatch(prepareFormula(id, price)),
        clearCart: () => dispatch(clearCart()),
    }
}


/* Second method
* const mapDispatchToProps = {
        addToCart
};
* */

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Product);
