/**
 * @flow
 *
 * The root element
 */
import React from 'react';
// import {Platform } from 'react-native';
import {AppNavigatorWrapper} from './config/router';
// import {checkManifest} from "../core/actions/App";
import {Provider} from 'react-redux'
import configureStore from './configureStore'
// import SplashScreen from 'react-native-splash-screen';

import { loadData } from '../core/managers/applicationManager';
// let packageInformation = require('../package.json');

const store = configureStore();


class App extends React.Component {

  state = {
      initialized: false
  };

  UNSAFE_componentWillMount() {
      loadData(store)
      .then((logged) => {
          this.setState({initialized: true});
          // SplashScreen.hide();
        }
      )
      .catch((e) => console.log(e))
    ;


    // isApplicationConformToManifest(store)
    //     .then((value) => {
    //     });

    // const check = checkManifest(packageInformation.version, 'client_' + Platform.OS);
    // console.log(check);
  }

  render() {
    return (
      <Provider store={store}>
        <AppNavigatorWrapper />
      </Provider>
    );
  }
}


export default App;
