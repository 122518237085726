/*
* @flow
*
* A number button matrix
*/
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';

import {connect} from 'react-redux';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import React from 'react';

const handleButton = (props) => {
    props.navigation.navigate('Cart');
};

let styles = StyleSheet.create({
    view: {

    },
    icon: {
        paddingLeft: 10,
        paddingRight: 15,
        bottom: -1,
    },
    badge: {
        position: 'absolute',
        bottom: 0,
        right: 5,
        backgroundColor: '#642668',
        fontSize: 9,
        borderRadius:10,
        borderWidth: 2,
        borderColor: '#fff',
        color: '#fff',
        padding: 3,
        paddingTop: 5,
        fontWeight: 'bold',
        overflow: 'hidden',
        textAlign: 'center',
        width: 21,
        height: 21,
    }
});

const CartButton = (props) => {
    return (
        <View style={styles.view}>
            {props.data.success &&
            <TouchableOpacity onPress={() => { handleButton(props) }}>
                <MaterialCommunityIcons
                    name="cart"
                    size={24}
                    color="white"
                    style={styles.icon} />
                {props.cart.header.numberOfArticles >= 1 &&
                <Text style={styles.badge}>{props.cart.header.numberOfArticles}</Text>
                }

            </TouchableOpacity>
            }
        </View>
    );
};

function mapStateToProps (state) {
    return {
        cart: state.cart,
        data: state.data,
    }
}

function mapDispatchToProps (dispatch) {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartButton);
