import axios from 'axios';
import { UserAPI } from '../api';

import { API_ADDRESSES } from '../../src/config/config';

/*
 * action types
 */

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const CLEAR_ERROR = 'CLEAR_ERROR';

export const ADDRESS_SUCCESS = 'ADDRESS_SUCCESS';
export const ADDRESS_FAILURE = 'ADDRESS_FAILURE';


/*
 * action creators
 */

export function logout() {
    return {
        type: LOGOUT
    }
}

export function loginPending() {
    return {
        type: LOGIN_PENDING
    }
}

export function loginSuccess(username, formattedName, token) {
    return {
        type: LOGIN_SUCCESS,
        username: username,
        formattedName: formattedName,
        token: token,
    }
}

export function loginFailure(error, detailedErrors) {
    return {
        type: LOGIN_FAILURE,
        error,
        detailedErrors
    }
}

export function clearError() {
    return {
        type: CLEAR_ERROR
    }
}

export function addressSuccess(addresses) {
    return {
        type: ADDRESS_SUCCESS,
        addresses: addresses,
    }
}

export function addressFailure() {
    return {
        type: ADDRESS_FAILURE,
    }
}

export function login(email, password) {
    return async (dispatch) => {
        dispatch(loginPending());

        try {
            const response = await UserAPI.login(email, password);
            const responseData = await response.json();
            if (responseData.token) {
                if (responseData.role === 'ROLE_CUSTOMER') {
                    dispatch(loginSuccess(email, responseData.formattedName, responseData.token));
                    dispatch(getAddresses());
                }
                else {
                    dispatch(loginFailure('BAD_CREDENTIAL', null));
                }
            }
            else {
                dispatch(loginFailure(responseData.message, responseData.errors));
            }
        }
        catch (error_1) {
            dispatch(loginFailure(error_1, null));
        }
    }
}

export function register(firstName, lastName, phoneNumber, email, password) {
    return async (dispatch) => {

        dispatch(loginPending());

        let params = {
            firstName,
            lastName,
            phoneNumber,
            user: {
                email: email.toLowerCase(),
                plainPassword: password,
            }
        };
        
        try {
            const response = await UserAPI.register(params);
            const responseData = await response.json();

            if (responseData.token) {
                dispatch(loginSuccess(email, responseData.formattedName, responseData.token));
            }
            else {
                let formatErrors = "";
                if('errors' in responseData) {
                    if(responseData.errors !== null &&  'children' in responseData.errors){
                        if('errors' in responseData.errors.children.firstName) {
                            formatErrors += "\n - " + responseData.errors.children.firstName.errors[0];
                        }
                        if('errors' in responseData.errors.children.lastName) {
                            formatErrors += "\n - " + responseData.errors.children.lastName.errors[0];
                        }
                        if('errors' in responseData.errors.children.phoneNumber) {
                            formatErrors += "\n - " + responseData.errors.children.phoneNumber.errors[0];
                        }
                        if('errors' in responseData.errors.children.user.children.email) {
                            formatErrors += "\n - " + responseData.errors.children.user.children.email.errors[0];
                        }
                        if('errors' in responseData.errors.children.user.children.plainPassword) {
                            formatErrors += "\n - " + responseData.errors.children.user.children.plainPassword.errors[0];
                        }
                    }
                }

                dispatch(loginFailure(responseData.message, formatErrors));
            }
        } catch (e) {
            console.log(e);
            dispatch(loginFailure(e, null));
        }        
    }
}

export function getAddresses() {

    return async (dispatch, getState) => {
        const {user} = getState();

        return await axios.get(API_ADDRESSES, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                }
            })
            .then(responseData => {
                if (responseData.status >= 200 && responseData.status < 300) {
                    dispatch(addressSuccess(responseData.data));
                }
                else {
                    dispatch(addressFailure());
                }
            })
        ; 
    }
}
