import React from 'react';
import { View, Platform } from 'react-native';
import { createStackNavigator } from 'react-navigation-stack';
import { createDrawerNavigator } from 'react-navigation-drawer';
import { createMaterialTopTabNavigator } from 'react-navigation-tabs';
import { createAppContainer } from 'react-navigation';
import { createBrowserApp } from "@react-navigation/web";

import Entypo from 'react-native-vector-icons/Entypo';
import {Icon} from 'react-native-elements';

import Product from '../screens/Product/Product';
import ProductDetails from '../screens/ProductDetails';

import Order from '../screens/Order';
import OrderDetails from '../screens/OrderDetails';

import PaymentState from '../screens/PaymentState';
import Error from '../screens/Error';

import Contact from '../screens/Contact';
import Faq from '../screens/Faq';

import Cart from '../screens/Cart';
import CartDetails from '../screens/CartDetails';
import Payment from '../screens/Payment';

import Authentication from '../screens/Authentication';

import FormulaComposition from '../screens/FormulaComposition';
import Delivery from '../screens/Delivery';

import {ArticleTypes, CategoryIds} from './config';

import {DrawerMenu, DrawerButton} from '../components/Drawer';
import {CartButton, DeliveryButton} from '../components/Menu';

// import I18n from '../i18n/i18n';

export const Products = createMaterialTopTabNavigator(
  {
    Formula: {
      screen: props => <Product main={true} articleType={ ArticleTypes.FORMULA } categoryId={ null } navigation={props.navigation}/>,
      navigationOptions: {
        tabBarLabel: 'Formule'.toUpperCase()
      }
    },
    Appetizer: {
      screen: props => <Product main={false} articleType={ ArticleTypes.PRODUCT } categoryId={ CategoryIds.APPETIZER } navigation={props.navigation}/>,
      navigationOptions: {
        tabBarLabel: 'Entrée'.toUpperCase()
      }
    },
    Course: {
      screen: props => <Product main={false} articleType={ ArticleTypes.PRODUCT } categoryId={ CategoryIds.COURSE } navigation={props.navigation}/>,
      navigationOptions: {
        tabBarLabel: 'Plat'.toUpperCase(),
      }
    },
    Dessert: {
      screen: props => <Product main={false} articleType={ ArticleTypes.PRODUCT } categoryId={ CategoryIds.DESSERT } navigation={props.navigation}/>,
      navigationOptions: {
        tabBarLabel: 'Dessert'.toUpperCase(),
      }
    },
    Drink: {
      screen: props => <Product main={false} articleType={ ArticleTypes.PRODUCT } categoryId={ CategoryIds.DRINK } navigation={props.navigation}/>,
      navigationOptions: {
        tabBarLabel: 'Boisson'.toUpperCase()
      }
    },
}, {
    lazy: true,
    swipeEnabled: true,
    animationEnabled: true,
    tabBarPosition: 'top',
    tabBarOptions: {
        tabStyle: {
            width: 100,
        },
        style: {
            backgroundColor: '#e6e6e6',

        },
        indicatorStyle: {
            backgroundColor: '#f59c26',
        },
        labelStyle: {
            fontSize: 12,
            fontWeight: 'bold',
        },
        activeTintColor: '#66256b',
        inactiveTintColor: '#c2c2c2',
        scrollEnabled: true
    },
    navigationOptions: ({navigation}) => ({
        //title: "XOXO Time",
    }),
  }
);


export const DrawerNavigator = createDrawerNavigator({
    Products: {
        screen: Products,
        navigationOptions: {
            drawerLabel: 'Commander',
            drawerIcon: ({ tintColor }) => <Entypo size={25} name="home" color={tintColor} />
        },
    },
    Faq : {
        screen: Faq,
        navigationOptions: {
            drawerLabel: 'FAQ',
            drawerIcon: ({ tintColor }) => <Entypo size={22}  name="help-with-circle" color={tintColor} />
        },
    },
    Contact : {
        screen: Contact,
        navigationOptions: {
          drawerLabel: 'Contact',
          drawerIcon: ({ tintColor }) => <Entypo size={26} name="location-pin" color={tintColor} />
        },
    },
    AuthenticationTab: {
        screen: Authentication,
        navigationOptions: {
            drawerLabel: 'Mon compte',
            drawerIcon: ({ tintColor }) => <Entypo size={22} name="user" color={tintColor} />
        },
    },
    Order: {
        screen: Order,
        navigationOptions: {
            drawerLabel: 'Mes commandes',
            drawerIcon: ({ tintColor }) => <Entypo size={22} name="box" color={tintColor} />
        },
    },
}, {
    initialRouteName: 'Products',
    contentComponent: DrawerMenu,
    contentOptions: {
        activeTintColor: '#65256b',
        activeBackgroundColor: '#eef2f4'
    },
    drawerBackgroundColor: '#65256b',
    drawerWidth: 300,
});


const stackNavigatorConfigs = {
  initialRouteName: "Index"
}

const routeConfigs = {
  Index: {
    screen: DrawerNavigator,
    navigationOptions: ({ navigation }) => ({
      title: "XOXO Time",
      headerStyle: {
        backgroundColor: '#fda127',
      },
      headerTintColor: '#fff',
      headerLeft: () => <DrawerButton navigation={navigation}  />,
      headerRight: () => (
        <View style={{flexDirection: 'row'}}>
          <DeliveryButton navigation={navigation} />
          <CartButton navigation={navigation} />
        </View>
      ),
    }),
  },
  Authentication: {
      screen: Authentication,
      navigationOptions: {
          drawerLabel: 'Mon compte',
          drawerIcon: ({ tintColor }) => <Entypo size={22} name="user" color={tintColor} />,
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      },
  },
  ProductDetails: {
      screen: ProductDetails,
      navigationOptions: ({ navigation }) => ({
          title: `${navigation.state.params.product.name}`,
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      }),
  },
  OrderDetails: {
      screen: OrderDetails,
      navigationOptions: ({ navigation }) => ({
          title: "Mes commandes",
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      }),
  },
  Cart: {
      screen: Cart,
      navigationOptions: ({ navigation }) => ({
          title: 'Votre panier',
          drawerLabel: 'Cart',
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
          // drawerIcon: ({ tintColor }) => <Icon theme={{ iconFamily: 'FontAwesome' }} size={26} name="shopping-basket" color={tintColor} />
      }),
  },
  CartDetails: {
      screen: CartDetails,
      navigationOptions: ({ navigation }) => ({
          title: 'Détails',
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      }),
  },
  Payment: {
      screen: Payment,
      navigationOptions: ({ navigation }) => ({
          title: 'Paiement',
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      }),
  },
  FormulaComposition: {
      screen: FormulaComposition,
      navigationOptions: ({ navigation }) => ({
          title: 'Composer votre menu',
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      }),
  },
  Delivery: {
      screen: Delivery,
      navigationOptions: ({ navigation }) => ({
          title: 'Adresse de livraison',
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      }),
  },
  Error: {
      screen: Error,
      navigationOptions: ({ navigation }) => ({
          title: "XOXO Time",
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      }),
  },
  PaymentState: {
      screen: PaymentState,
      navigationOptions: ({ navigation }) => ({
          title: 'État de la commande',
          headerBackTitle: 'Retour',
          headerStyle: {
            backgroundColor: '#fda127',
          },
          headerTintColor: '#fff',
      }),
  },
};

const RootStack = createStackNavigator(routeConfigs, stackNavigatorConfigs);

const createApp = Platform.select({
  web: config => createBrowserApp(config, {history: 'hash'}),
  default: config => createAppContainer(config)
})

export const AppNavigatorWrapper = createApp(RootStack);
