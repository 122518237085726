// @flow
import { Dimensions, StyleSheet } from 'react-native';

const {height, width} = Dimensions.get('window');
const itemWidth = (width - 45) / 4;

export default StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        color: '#65256b',
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 10,
        marginTop: 15,
    },
    content: {
        margin: 10,
        flexDirection: 'column',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    contentAutoComplete: {
        margin: 10,
        height: 200
    },
    blockContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    block: {
        margin: 3,
        width: itemWidth,
        height: 80,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        shadowOpacity: 0.25,
        shadowRadius: 2,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
        borderRadius: 2,
    },
    blockSelected: {
        borderWidth: 1,
        borderColor: '#32a583'
    },
    blockSelectedText: {
        color: '#32a583',
    },
    blockText: {
        color: '#000',
    },
    blockTextSmall: {
        color: '#000',
        fontSize: 12,
    },
    blockStepValidated: {
        padding: 6,
        margin: 3,
        marginBottom: 10,
        backgroundColor: '#fff',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
        borderWidth: 1,
        borderColor: '#32a583',
        borderRadius: 2,
    },
    blockStepValidatedText: {
        color: '#32a583',
        lineHeight: 20,
        textAlign: 'center'
    },
    blockStepNotValidated: {
        padding: 6,
        margin: 3,
        marginBottom: 10,
        backgroundColor: '#fff',
        shadowOpacity: 0.25,
        shadowRadius: 2,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#fff',
    },
    blockStepNotValidatedText: {
        lineHeight: 20,
        textAlign: 'center',
    },
    blockStepNotValidatedTextBig: {
        lineHeight: 20,
        textAlign: 'center',
        color: '#65256b',
    },
    hourStepValidatedRow: {
        flexDirection: 'row'
    },
    hourStepValidatedBlock: {
        flex: 1
    },
    footer: {
        flex: -1
    },
    validateButton: {
        margin: 5,
        borderRadius: 2,
        alignSelf: 'stretch',
        backgroundColor: '#fda127',
    },
    validateButtonDisabled: {
        margin: 5,
        borderRadius: 2,
        alignSelf: 'stretch',
        backgroundColor: '#dddddd',
    }
});
