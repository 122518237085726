import React, {Component} from 'react';
import {Platform, ImageBackground, Text, View} from 'react-native';
import Foundation from 'react-native-vector-icons/Foundation';
// import I18n from '../../i18n/i18n';
import {checkAppStateAPI} from '../../../core/actions/App';
import {fetchData} from '../../../core/actions/Data';
import {connect} from 'react-redux';
import {NavigationActions, StackActions} from 'react-navigation';


import {Button} from 'react-native-elements';

import styles from './styles';
import Container from "../../components/Container";


class Error extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    };

    retry() {
        let key = this.props.navigation.state.params.key;

        switch(key){
            case 'MAINTENANCE':
                this.props.checkAppStateAPI().then(() => {
                    //TODO c'est un peu sale
                    this.props.fetchData();

                    if(!this.props.app.maintenance) {
                        const resetAction = StackActions.reset({
                            index: 0,
                            actions: [
                                NavigationActions.navigate({routeName: 'Index'})
                            ]
                        });
                        this.props.navigation.dispatch(resetAction);
                    }
                });
                break;

            default:
                this.props.navigation.goBack();
        }

    };

    cancel() {
        this.props.navigation.goBack();
    };

    render() {
        let error, title, key;


        const errorMessages = {
            DEFAULT_ERROR: "Un problème est survenu lors de votre commande. Si le problème persiste merci de nous contacter.",
            DEFAULT_AUTHENTICATION_ERROR: "Impossible de vous authentifier. Merci de réessayer et de nous contacter.",
            MAINTENANCE: "Le serveur est en maintenance, merci de réessayer plus tard.",
            OUTDATED: "La version que vous utilisée n'est plus supportée. Merci de télécharger la nouvelle version de l'application.",
            INVALID_REQUEST: "Votre requête est invalide. Merci de recommencer ou de nous contacter si le problème persiste.",
            ADDRESS_INVALID: "Aucune livraison n'est possible pour votre adresse. Merci de recommencer ou de nous contacter si le problème persiste.",
            ADDRESS_NOT_DELIVERED: "Aucune livraison n'est possible pour votre addresse.",
            CART_MINIMUM_NOT_REACHED: "Le montant de votre panier est inférieur au montant minimum requis.",
            DELIVERY_DATE_TOO_SOON: "Impossible de livrer votre panier à cette date.",
            DELIVERY_DATE_OUT_OF_OPENING_HOURS: "Impossible de livrer votre panier à cette date.",
            INVALID_ARTICLE: "Un des articles que vous avez choisis n'est plus disponible en magasin. Pourriez-vous recommencer votre commande ?",
            INVALID_TOKEN: "Merci de vous reconnecter afin d'accèder à toutes les fonctionnalités de l'application.",
            EXPIRED_TOKEN: "Votre session n'est plus valide. Merci de vous reconnecter.",
            MISSING_TOKEN: "Votre session n'est plus valide. Merci de vous reconnecter.",
            BAD_CREDENTIAL: "Votre identifiant n’est pas valide.",

        };

        if(this.props.navigation.state.params.error) {
            error = this.props.navigation.state.params.error;
        }
        else {
            error = 'error.DEFAULT_ERROR';
        }

        key = this.props.navigation.state.params.key;
        if(key === 'MAINTENANCE') {
            title = 'Maintenance';
        }
        else if(key === 'OUTDATED'){
            title = 'Version non à jour';
        }
        else {
            title = 'Erreur';
        }

        return (
            <View
                style={styles.container}>

                <ImageBackground style={styles.errorOverlay} source={require('../../resources/img/transparent-pattern.png')}>
                    <Foundation style={styles.icon}
                        size={70}
                        name="alert"
                        color={'#fff'} />
                    <Text style={styles.title}>{ title }</Text>
                    <Text style={styles.message}>{error ? errorMessages.error : errorMessages.DEFAULT_ERROR}</Text>
                </ImageBackground>

                <View style={styles.footer}>
                    <Container size={'small'} heightAuto={true}>
                        <Button
                            raised={(Platform.OS === 'ios')}
                            buttonStyle={styles.button}
                            onPress={() => this.retry()}
                            title='Recommencer'/>

                        <Button
                            buttonStyle={styles.cancelButton}
                            textStyle={styles.cancelButtonText}
                            onPress={() => this.cancel()}
                            title='Retour'/>
                    </Container>
                </View>

            </View>
        )
    }


}

function mapStateToProps(state) {
    return {
        app: state.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        checkAppStateAPI: () => dispatch(checkAppStateAPI()),
        fetchData: () => dispatch(fetchData()),
    }
}

/* Second method
* const mapDispatchToProps = {
        addToCart
};
* */

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Error);
