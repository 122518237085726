// @flow
import {Dimensions, StyleSheet} from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
    },
    cart: {
        flex: 1,
    },
    payment: {
        flex: -1,
        flexDirection: 'column'
    },
    item: {
        backgroundColor: 'white',
        marginTop: 7,
        marginBottom: 5,
        marginLeft: 7,
        marginRight: 7,
        borderColor: '#d6d7da',
        padding: 15,
        paddingBottom: 10,
        paddingTop: 8,
        flex: 1,
        flexDirection: 'row',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    itemLeft: {
        flex: -1,
        alignSelf: 'center'
    },
    itemCenter: {
        flex: 1,
        marginLeft: 16,
        alignSelf: 'center',
    },
    itemRight: {
        flex: -1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    itemRightTop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    itemRightBottom: {
        alignSelf: 'flex-end',
        marginTop: 6
    },
    productTitle: {
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#fda127'
    },
    productDescription: {},
    productQuantity: {
        color: '#32a583',
        fontWeight: 'bold'
    },
    productPrice: {
        color: '#fda127',
        fontWeight: 'bold',
        fontSize: 12
    },
    plusMinusButton: {
        alignSelf: 'center',
        marginLeft: 7
    },
    recap: {
        backgroundColor: '#fff',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
    },
    recapLine: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 5,
    },
    recapLineTitle: {
        flex: 1,
        fontSize: 15
    },
    recapLineData: {
        flex: -1,
        fontSize: 15
    },
    note: {
        fontSize: 14,
        color: '#a63a32',
        fontWeight: 'bold'
    },
    horizontalSeparator: {
        borderBottomColor: '#e0e3e5',
        borderBottomWidth: 1,
        marginTop: 12,
        marginBottom: 12,
    },
    bold: {
        fontWeight: 'bold'
    },
    boldColor: {
        fontWeight: 'bold',
        color: '#fda127'
    },
    buttonBlock: {
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 5,
        marginBottom: 125,
        marginTop: 10
    },
    payButtonText: {
        fontSize: 14,
        textAlign: 'center'
    },
    payButtonContainer: {
        flex: 1,
        alignSelf: 'stretch'
    },
    roundedButton: {
        borderRadius: 2,
    },
    payNowButton: {
        backgroundColor: '#fda127',
    },
    payLaterButton: {
        backgroundColor: '#67256a',
    },
    buttonDisabled: {
        borderRadius: 2,
        alignSelf: 'stretch',
        backgroundColor: '#dddddd',
    },
    emptyView: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignContent: 'center'
    },
    emptyText: {
        color: "#848484",
        textAlign: 'center'
    },
    emptyIcon: {
        marginTop: 20,
        alignSelf: 'center',
       /* transform: [
            {rotateX: '180deg'},
            {rotateY: '180deg'},
        ], */
    }
});