import {API_VALIDATE_CART_URL, API_PROMOTION_CODE_URL, API_ORDER_STATUS, API_ORDER} from '../../src/config/config';
import {ArticleTypes} from '../../src/config/config';

import {FetchHelper} from '../managers';


export const formatData = (cart, paymentOnline) => {

    return {
        wishDeliveryDate: createISODate(cart.delivery.date, cart.delivery.hour, cart.delivery.minute),
        paymentOnline: paymentOnline,
        position : {
            lat: cart.delivery.latitude + '',
            lng: cart.delivery.longitude + ''
        },
        formulas : extractArticles(cart.articles, ArticleTypes.FORMULA),
        products : extractArticles(cart.articles, ArticleTypes.PRODUCT),
        salt: cart.details.salt,
        cutlery: cart.details.cutlery,
        promotionCode: cart.details.promoCode,
        remark: cart.details.remark,
        complementaryAddress: cart.delivery.complementary,
        origin: 'web'
    };

};

Date.prototype.toIsoString = function() {
    let tzo = -this.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            let norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return this.getFullYear() +
        '-' + pad(this.getMonth() + 1) +
        '-' + pad(this.getDate()) +
        'T' + pad(this.getHours()) +
        ':' + pad(this.getMinutes()) +
        ':' + pad(this.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
};

const createISODate = (date, hours, minutes) => {
    let formattedDate = new Date(date);
    formattedDate.setHours(hours);
    formattedDate.setMinutes(minutes);

    formattedDate.toIsoString();

    return formattedDate.toIsoString();
};

const extractArticles = (articles, type) => {
    let extractedArticles = [];
    let formattedArticle = null;

    Object.keys(articles).forEach(function (key) {
        if (articles[key].articleType === type) {
            formattedArticle = {
                id: articles[key].id,
                quantity: articles[key].quantity,
            };

            if(type === ArticleTypes.FORMULA) {
                formattedArticle.compositions = [];
                Object.keys(articles[key].list).forEach(function (subKey) {
                    formattedArticle.compositions.push({
                        idComposition: parseInt(subKey),
                        idProduct: articles[key].list[subKey].id
                    });
                });
            }
            extractedArticles.push(formattedArticle);
        }
    });

    return extractedArticles;
};

export function validateCart(dispatch, token, cart, paymentOnline) {

    let formattedCart = formatData(cart, paymentOnline);
    return FetchHelper.decoratedFetch(dispatch, API_VALIDATE_CART_URL, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formattedCart)
    });

}

export function isPromotionAvailable(promoCode) {

    let promoCodeURL = API_PROMOTION_CODE_URL.replace("{{code}}", promoCode);
    return fetch(promoCodeURL);

}

export function checkOrderStatus(dispatch, orderId, token) {

    let orderStatusURL = API_ORDER_STATUS.replace("{{orderId}}", orderId);
    return FetchHelper.decoratedFetch(dispatch, orderStatusURL, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
    });
}

export function fetchOrder(dispatch, orderId, token) {

    let orderStatusURL = API_ORDER.replace("{{orderId}}", orderId);
    return FetchHelper.decoratedFetch(dispatch, orderStatusURL, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
    });
}
