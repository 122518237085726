// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
    },
    pageHeader: {
        height: 100,
        width: width,
    },
    pageHeaderTitle: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,
    },
    title: {
        textAlign: 'center',
        fontSize: 22,
        fontWeight: '300',
        marginBottom: 20,
    },
    header: {
        backgroundColor: 'white',
        marginTop: 12,
        marginLeft: 7,
        marginRight: 7,
        borderColor: '#d6d7da',
        padding: 15,
        paddingBottom: 10,
        flex: 1,
        flexDirection: 'row',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    headerText: {
        flex: 1,
        textAlign: 'left',
        fontSize: 14,
        fontWeight: '600'
    },
    expandIcon: {
        alignSelf: 'center'
    },
    content: {
        padding: 15,
        paddingTop: 5,
        marginLeft: 7,
        marginRight: 7,
        backgroundColor: '#fff',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    active: {
        backgroundColor: 'rgba(255,255,255,1)',
    },
    inactive: {
        backgroundColor: 'rgba(245,252,255,1)',
    },
    selectors: {
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    selector: {
        backgroundColor: '#F5FCFF',
        padding: 10,
    },
    activeSelector: {
        fontWeight: 'bold',
    },
    selectTitle: {
        fontSize: 14,
        fontWeight: '500',
        padding: 10,
    },

});