import React, {Component} from 'react';
import {ImageBackground, View, ScrollView, Text} from 'react-native';
import {Icon} from 'react-native-elements';
import Accordion from 'react-native-collapsible/Accordion';

import styles from './styles';
import Container from "../../components/Container";

const SECTIONS = [
    {
        title: "XOXO Time c'est quoi ?",
        content: "Xoxo Time, est une société de livraison de burgers et de plats africain de nuit, de 20h à 6h du matin."
        + "\n\nLa livraison est réalisée à température de dégustation et vous permet de savourer nos spécialités où que vous soyez et ceci durant toute la nuit. Vous pouvez même précommander par téléphone au 01.34.46.15.15 ou via l’application mobile Xoxo Time sur Apple Store ou Android."
    },
    {
        title: "Où est ce que livre XOXO Time ?",
        content: "XOXO Time vous livre dans toute l’Ile de France, avec un minimum de 20€* de commande.\n *Précommande et minimum de commande de 50€ pour les départements du 77 et 91."
    },
    {
        title: "Combien de temps faut-il pour me faire livrer ?",
        content: "Pour une commande en live nos livreurs arrivent entre 30 à 40 minutes en moyenne, si vous précommandez, c’est vous qui choisissez le créneau horaire qui vous convient et êtes prioritaires. Notre créneau étant de vous livrer en temps et en heure."
    },
    {
        title: "Puis-je commander à l'avance ?",
        content: "Vous pouvez tout à fait précommander votre repas via notre application ou par téléphone au 01.34.46.15.15 en choisissant un créneau horaire allant de 11h00 à 22h30. Si vous le souhaitez, vous pouvez précommander jusqu’à une semaine à l’avance."
    },
    {
        title: "Le livreur m'apporte t-il le repas à ma porte ?",
        content: "Oui, le livreur sonne et vous livre chez vous ou sur votre lieu de travail. Vous n’avez pas besoin de sortir."
    },
    {
        title: "Quelles sont les mesures alimentaires concernant le transport et la livraison des plats ?",
        content: "Nos plats sont préparés et conditionnés dans notre laboratoire, répondant aux normes HACCP, de manière à vous livrer en frais. Ils sont ensuite transportés dans des camions de livraison réfrigérés. Du conditionnement à la livraison la chaine du froid est respectée."
    },
    {
        title: "Comment dois-je préparer mes plats XOXO Time ?",
        content: "Votre repas vous est livré en frais dans une barquette micro-ondable. Lorsque que vous le réchaufferez il faudra veillez à retirer de la barquette les petits contenants à sauce. Vous pouvez laisser les barquettes fermées. Le temps de réchauffage, donné à titre indicatif est de 3 ou 4 minute. Nos plats peuvent être réchauffés au four dans un plat adapté ou à la poêle, si besoin."
    },
    {
        title: "Combien de temps les plats XOXO Time se conservent-ils ?",
        content: "Reportez-vous à la Date Limite de Consommation (DLC) qui figure sur l’emballage du plat. Nos plats sont élaborés la veille pour le lendemain vous pourrez donc les conserver, selon la loi en rigueur, 3 jours. "
    },
    {
        title: "Il y a t-il un montant de commande minimum à respecter ?",
        content: "Un minimum de commande vous est indiqué lors de votre commande. Ce qui nous permet de vous offrir les frais de livraison. "
    },
    {
        title: "Puis-je recevoir une facture pour mes commandes ?",
        content: "Une facture vous est envoyée automatiquement par email."
    },
    {
        title: "Puis-je annuler ou modifier ma précommande ?",
        content: "Vous pouvez annuler ou modifier votre précommande en contactant notre service client au 01.34.46.15.15."
    },
    {
        title: "Puis-je annuler ou modifier ma commande ?",
        content: "Nous tâchons de vous livrer de manière rapide, une fois votre commande passée et transmise à votre livreur nous ne pouvons pas annuler cette dernière. Dans le cas où vous souhaiteriez ajouter ou modifier des éléments à votre commande, vous pouvez simplement passer une nouvelle commande dans le cas d’un règlement par CB ou voir directement avec votre livreur."
    },
    {
        title: "Le site XOXO Time est-il sécurisé pour ma carte de crédit ?",
        content: "Vos informations de paiement ne sont à aucun moment en possession de XOXO Time. Elles transitent par un tiers de confiance spécialisé dans le paiement en ligne qui utilise le protocole SSL qui garantit le cryptage et la sécurisation optimale de vos données. "
    }
];

class Faq extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        activeSections: [],
    };

    componentDidMount() {

    };

    render() {
        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.png')}>
                <ScrollView>
                    <ImageBackground
                        source={require('../../resources/img/background-pattern-reverse.jpg')}
                        style={styles.pageHeader}
                        resizeMode="cover">

                        <Text style={styles.pageHeaderTitle}>
                            Nos réponses à vos {'\n'} questions les plus fréquentes
                        </Text>


                    </ImageBackground>
                    <Container size={'small'}>
                        <Accordion
                            sections={SECTIONS}
                            underlayColor="transparent"
                            activeSections={this.state.activeSections}
                            renderHeader={this._renderHeader}
                            renderContent={this._renderContent}
                            onChange={this._updateSections}
                        />
                    </Container>
                </ScrollView>
            </ImageBackground>
        )
    }

    _renderHeader = (section, index, isActive) => {
        return (
            <View style={styles.header}>
                <Text style={styles.headerText}>{section.title}</Text>
                <Icon
                    theme={{iconFamily: 'FontAwesome'}}
                    name={isActive ? 'expand-more' : 'expand-less'}
                    size={30}
                    color="#f4a126"
                    style={styles.expandIcon}
                />
            </View>
        );
    }

    _renderContent = section => {
        return (
            <View style={styles.content}>
                <Text>{section.content}</Text>
            </View>
        );
    }

    _updateSections = activeSections => {
        this.setState({ activeSections });
    }
}

export default Faq;
