import React from 'react';
// import {View, ActivityIndicator} from 'react-native';
import {connect} from 'react-redux';
import {NavigationActions, StackActions} from 'react-navigation';
// import styles from './styles';
import {LoadingWithBackground} from "../../components/Loading";
import {paymentIsPayed, validatePayment} from '../../../core/actions/Cart';

class Payment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            canGoBack: false,
            goToPaymentPage: this.props.navigation.state.params.goToPaymentPage ?? false,
            cart: this.props.navigation.state.params.cart ?? null,
            data: null,
        };
    }

    componentDidMount() {
        if (this.state.goToPaymentPage) {
            window.location.href = this.props.navigation.state.params.url;
        }

        if (this.props.navigation.getParam('data')) {
            const data = JSON.parse(this.props.navigation.getParam('data'));

            window.setTimeout(
              () => this.doPayment(data),
              10
            );

            this.setState({
                data
            });
        }

    };

    componentWillUnmount() {

    };

    goToPaymentStatePage(canBePending, cart) {

        const resetAction = StackActions.reset({
            index: 3,
            actions: [
                NavigationActions.navigate({routeName: 'Index'}),
                NavigationActions.navigate({routeName: 'Cart'}),
                NavigationActions.navigate({routeName: 'CartDetails'}),
                NavigationActions.navigate({
                    routeName: 'PaymentState',
                    params: {...{cart, canBePending}}
                }),
            ]
        });
        this.props.navigation.dispatch(resetAction);
    };

    async doPayment(data) {
        const isPayed = await paymentIsPayed(data['orderID']);
        if (isPayed) {
            validatePayment(data);
        }

        this.setState({
            loading: true,
        });

        // Waiting before, to be sure that ingenico has communicated with our API
        setTimeout(() => {

            this.goToPaymentStatePage(true, data['orderID']);

        }, 100);
    }

    render() {

        if (this.state.loading) {
            return (
                <LoadingWithBackground/>
            )
        }

    }
}


function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Payment);
