// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    backgroundImage: {
        height: height,
        width: width,
        flex: 1,
        justifyContent: 'center',
    },
    central: {
        marginBottom: 50
    },
    centralParent: {
        flex: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    centralBlock: {
        marginTop: 100,
        backgroundColor: 'white',
        borderRadius: 3,
        padding: 20
    },
    title: {
        color: '#65256b',
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 15,
    },
    content: {
        textAlign: 'justify',
        marginBottom: 15,
    },
    buttonBlock: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 5
    },
    callButton: {
        backgroundColor: '#fda127',
        borderRadius: 2
    },
    mailButton: {
        backgroundColor: '#67256a',
        borderRadius: 2
    },
    bottomBlock: {
        flex: 0,
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    socialIconStyle: {
        borderWidth: 3,
        borderColor: '#d6d7da',
        backgroundColor: 'transparent'
    }
});