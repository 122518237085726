import axios from 'axios';
import {API_PRODUCTS_URL, URL_MANIFEST} from '../../src/config/config';
import FetchHelper from '../managers/fetchHelper';
import VersionHelper from '../managers/versionHelper';

/*
 * action types
 */
export const SET_WORKING = 'SET_WORKING';

export const SET_MAINTENANCE = 'SET_MAINTENANCE';
export const SET_OFFLINE = 'SET_OFFLINE';
export const SET_OUTDATED = 'SET_OUTDATED';

/*
 * action creators
 */

export function setMaintenanceMode() {
    return {
        type: SET_MAINTENANCE
    }
}

export function setOfflineMode() {
    return {
        type: SET_OFFLINE,
    }
}

export function setOutdatedMode() {
    return {
        type: SET_OUTDATED,
    }
}

export function setWorking() {
    return {
        type: SET_WORKING,
    }
}

export async function checkAppStateAPI() {
    // return async (dispatch) => {

        const data =  await axios({
            method: 'HEAD',
            url: API_PRODUCTS_URL, 
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        // console.log(data);
        return data;

        // return FetchHelper.decoratedFetch(dispatch, API_PRODUCTS_URL, {
        //     method: 'HEAD',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     }
        // })

    // }
}

export function checkManifest(version, appName) {
    return (dispatch) => {

        return FetchHelper.decoratedFetch(dispatch, URL_MANIFEST)
            .then(responseData => {

                if (responseData.success && 'applications' in responseData.data && appName in responseData.data.applications) {
                    if(VersionHelper.versionCompare(version, responseData.data.applications[appName].minVersion) < 0) {
                        dispatch(setOutdatedMode());
                        return false;
                    }
                }
                return true;
            });

    }
}

