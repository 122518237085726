import React from 'react';
import { View } from 'react-native';
import GooglePlacesAutocomplete from 'react-native-google-places-autocomplete'

class AutoCompletePlaces extends React.Component {
  render() {
    return (
      <View style={{ flex: 1 }}>
        <GooglePlacesAutocomplete
          placeholder={this.props.label}
          minLength={2} // minimum length of text to search
          autoFocus={false}
          returnKeyType={'search'} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
          listViewDisplayed="auto" // true/false/undefined
          fetchDetails={true}
          // renderDescription={row => row.description} // custom description render
          onPress={(data, details = null) => {
            // console.log(data);
            // console.log(details);
            this.props.onSelect(details);
          }}
          getDefaultValue={() => {
            return this.props.address ? this.props.address : ""; // text input default value
          }}
          requestUrl={{
            useOnPlatform: 'web',
            url: "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api"
          }}
          query={{
            // available options: https://developers.google.com/places/web-service/autocomplete
            key: 'AIzaSyBdh5bb39kSCFiE52F7_X2BHw7PAALUa9M',
            language: 'fr', // language of the results
            types: 'address', // default: 'geocode'
            components: 'country:fr',
            latitude: 48.866667,
            longitude: 2.333333,
            radius: 10
          }}
          styles={{
            description: {
              fontWeight: 'bold',
            },
            predefinedPlacesDescription: {
              color: '#1faadb',
            },
          }}
          // currentLocation={true} // Will add a 'Current location' button at the top of the predefined places list
          // currentLocationLabel="Posititon actuelle"
          nearbyPlacesAPI="GooglePlacesSearch" // Which API to use: GoogleReverseGeocoding or GooglePlacesSearch
          GoogleReverseGeocodingQuery={
            {
              // available options for GoogleReverseGeocoding API : https://developers.google.com/maps/documentation/geocoding/intro
            }
          }
          GooglePlacesSearchQuery={{
            // available options for GooglePlacesSearch API : https://developers.google.com/places/web-service/search
            rankby: 'distance',
            types: 'food',
          }}
          filterReverseGeocodingByTypes={[
            'locality',
            'administrative_area_level_3',
          ]} // filter the reverse geocoding results by types - ['locality', 'administrative_area_level_3'] if you want to display only cities
          // predefinedPlaces={[homePlace, workPlace]}
          debounce={200}
        />
      </View>
    );
  }
}

export default AutoCompletePlaces;
