// @flow
import {combineReducers} from 'redux';

import rehydrated from './rehydrated';
import cart from './cart';
import data from './data';
import user from './user';
import order from './order';
import app from './app';

const appReducer = combineReducers({
    rehydrated,
    cart,
    data,
    user,
    order,
    app
});

const rootReducer = (state, action) => {
    // Handle a root reducer for global actions like user logout
    if (action.type === 'CLEAR_DATA') {
        state = undefined;
    }

    return appReducer(state, action)
};

export default rootReducer;
